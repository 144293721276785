/*
 * This redirect is a temporary measure to ensure proper
 * redirecting to SUX pages on client-side history navigation
 */
import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

function ExternalRedirect({ location: { pathname } }) {
  if (__CLIENT__) {
    window.location = pathname;
  }

  return <Route status={302} to={pathname} />;
}

ExternalRedirect.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

ExternalRedirect.defaultProps = {
  location: {
    pathname: '',
  },
};

export default ExternalRedirect;
