export const ERROR_NAME_ID = 'IDError';
export const ERROR_NAME = 'UIManagerError';

class UIManagerError extends Error {
  constructor(message, name = ERROR_NAME) {
    super(message);
    this.name = name;
  }
}

export default UIManagerError;
