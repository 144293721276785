import logger from 'utils/logger';
import JazzHRClient from '@rex-change/rexlib/dist/JazzHRClient';
import { REX_SVC_JAZZHR_HOST } from 'constants/api';
import LoadableCareersPage from './CareersPage.loadable';

const client = new JazzHRClient({
  baseURL: REX_SVC_JAZZHR_HOST,
});

async function fetchData() {
  let jobs;
  let locations;
  let departments;

  try {
    jobs = await client.getJobs({
      sort: 'location',
    });
  } catch (err) {
    logger.error(err.message || err);
    jobs = [];
  }

  try {
    locations = await client.getLocations();
  } catch (err) {
    logger.error(err.message || err);
    locations = [];
  }

  try {
    departments = await client.getDepartments();
    departments = departments.map((dept) => dept || 'Other').sort();
  } catch (err) {
    logger.error(err.message || err);
    departments = [];
  }

  return {
    jobs,
    locations,
    departments,
  };
}

export default {
  path: '/careers',
  exact: true,
  component: LoadableCareersPage,
  fetchData,
};
