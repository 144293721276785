import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { withRouter } from 'react-router';
import { string as yupString, object as yupObject } from 'yup';
import { UserContext } from 'contexts/UserContext/UserContext';
import Modal from 'components/Modal';
import './ForgotPasswordModal.scss';
import ForgotPasswordForm from '../ForgotPasswordForm';

export class ForgotPasswordModal extends React.Component {
  static formSchema = yupObject().shape({
    email: yupString()
      .email('A valid email address is required')
      .required('An email address is required.'),
  });

  static initialValues = {
    email: '',
  };

  state = { openModal: false };

  id = uuid();

  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }).isRequired,
    hash: PropTypes.string.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
  };

  static getDerivedStateFromProps(props, state) {
    const nextState = {
      ...state,
    };
    const { location, hash } = props;
    const currHash = location && location.hash;

    nextState.openModal = currHash === hash;

    return nextState;
  }

  closeDialog = () => {
    const { history, location } = this.props;

    const loc = { ...location, hash: '' };

    history.replace(loc);
  };

  render() {
    const { openModal } = this.state;

    return (
      <Modal
        contentLabel="Forgot Password?"
        open={openModal}
        onClose={this.closeDialog}
        className="rex-forgot-password-modal"
      >
        <ForgotPasswordForm onSuccess={this.closeDialog} />
      </Modal>
    );
  }
}

const ForgotPasswordModalRouter = withRouter(ForgotPasswordModal);

export default (props) => (
  <UserContext.Consumer>
    {({ user, forgotPassword }) => (
      // Disabled as this is a pass-through for non-context props
      // eslint-disable-next-line react/jsx-props-no-spreading
      <ForgotPasswordModalRouter {...props} user={user} forgotPassword={forgotPassword} />
    )}
  </UserContext.Consumer>
);
