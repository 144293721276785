import isEmpty from 'lodash/isEmpty';
import CMSClient from '@rex-change/rexlib/dist/CMSClient';
import GeoClient from '@rex-change/rexlib/dist/GeoClient';
import logger from 'utils/logger';
import { REX_SVC_REX_APP_HOST, REX_SVC_CMS_HOST } from 'constants/api';
import LoadableLanding from './LandingPage.loadable';

const cmsClient = new CMSClient({
  baseURL: REX_SVC_CMS_HOST,
});

const geoClient = new GeoClient({
  baseURL: REX_SVC_REX_APP_HOST,
});

async function fetchData({ fieldmetaKey }) {
  let fieldmeta = {};
  let states;

  try {
    fieldmeta = await cmsClient.getKVP(`rex-lander-${fieldmetaKey}`);
  } catch (err) {
    logger.error(err.message);
    logger.error(err.stack);
    throw err;
  }

  if (isEmpty(fieldmeta)) {
    logger.error(`Key not found in fieldmeta: ${fieldmetaKey}`);
    throw new Error(`Key not found in fieldmeta: ${fieldmetaKey}`);
  }

  try {
    states = await geoClient.getStates();
  } catch (error) {
    logger.error(error.message);
    logger.error(error.stack);

    states = [];
  }

  if (fieldmeta.states && fieldmeta.states.length) {
    states = states.filter(({ stateAbbreviation }) => fieldmeta.states.includes(stateAbbreviation));
  }

  return {
    lander: fieldmeta,
    states,
  };
}

export default {
  path: '/(compare|region)/:fieldmetaKey',
  exact: true,
  component: LoadableLanding,
  fetchData: ({ match: { params } }) => fetchData(params),
};
