import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useRexUser } from '@rex-change/brix';
import { makeStyles } from '@material-ui/core';
import LoadingOvertake from 'components/LoadingOvertake';

const useStyles = makeStyles({
  heightForce: {
    height: '100vh',
  },
});

function PrivateRoute({ component: Component, ...rest }) {
  const { isLoggedIn, isLoading, addAuthCallback, user } = useRexUser();
  const { push } = useHistory();
  const classes = useStyles();

  const confirmedIsLoggedIn = isLoggedIn && !!user;

  if (isLoading) {
    return (
      <div className={classes.heightForce}>
        <LoadingOvertake />
      </div>
    );
  }

  let content;
  if (confirmedIsLoggedIn) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    content = (props) => <Component {...props} />;
  } else {
    const initialUrl = (__CLIENT__ && window?.location?.pathname) || '/';
    addAuthCallback(() => {
      push(initialUrl);
    });
    content = <Redirect to="/signin" />;
  }

  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Route {...rest}>{content}</Route>
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
