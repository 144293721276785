import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const ResetPasswordRedirectPageLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'reset-password' */ 'containers/ResetPasswordRedirectPage'),
  loading: Loading,
});

export default ResetPasswordRedirectPageLoadable;
