import logger from 'utils/logger';
import RexAPI from '@rex-change/rexlib/dist/RexAPI';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import RedirectTokenPageLoadable from './RedirectTokenPage.loadable';

const ADMIN_PATH = '/admin-auth';

async function fetchData({ redirectToken }) {
  const client = new RexAPI({ baseURL: REX_SVC_REX_APP_HOST });
  let route;
  let isAdmin = false;
  try {
    logger.info(`/api/redirect/${redirectToken}`);
    const response = await client.get(`/api/redirect/${redirectToken}`);
    route = response.data.route;
    if (route.includes(ADMIN_PATH)) {
      isAdmin = true;
    }
  } catch (err) {
    logger.warn(err.message || err);
    route = '';
  }
  return { isAdmin, route };
}

export default {
  path: '/redirect/:redirectToken',
  exact: true,
  component: RedirectTokenPageLoadable,
  fetchData: ({ match: { params } }) => fetchData(params),
};
