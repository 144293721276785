import React from 'react';
import PropTypes from 'prop-types';
import { Modal as MuiModal, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    minHeight: '100vh',
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 99999,
  },
  content: {
    background: theme.palette.common.white,
    display: 'grid',
    gridTemplateAreas: "'buttons' 'header' 'body' 'footer'",
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto auto 1fr auto',
    minHeight: '100vh',
    padding: '.5rem',
    position: 'relative',
    width: '100vw',
    [theme.breakpoints.up('sm')]: {
      borderRadius: '.5rem',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, .6)',
      height: 'auto',
      margin: '0 auto',
      maxWidth: '38rem',
      minHeight: 0,
      padding: '1.5rem',
      paddingTop: '.5rem',
      width: '80vw',
    },
  },
  button: {
    background: 'transparent',
    border: 0,
    borderRadius: 0,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'inline-block',
    fontSize: '2rem',
    gridArea: 'buttons',
    justifySelf: 'right',
    lineHeight: '.8',
    margin: '0 0 0 auto',
    outline: 0,
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      opacity: '.8',
      transition: 'opacity ease-in-out .2s',
      '&:hover': {
        opacity: 1,
      },
    },
  },
  header: {
    borderBottom: 'thin solid',
    borderBottomColor: theme.palette.grey[400],
    gridArea: 'header',
    marginBottom: '1.5rem',
    marginLeft: '(-.5rem)',
    marginRight: '(-.5rem)',
    padding: '0 .5rem',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '(-1.5rem)',
      marginRight: '(-1.5rem)',
      padding: '0 1.5rem',
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'normal',
    marginBottom: '.5rem',
    marginTop: 0,
    textTransform: 'uppercase',
  },
  body: {
    gridArea: 'body',
  },
}));

/**
 * @deprecated please use the new material-ui dialog component from now on
 */
function Modal({
  children,
  className,
  buttonClassName,
  contentLabel,
  showCloseButton,
  onClose,
  ...props
}) {
  const classes = useStyles();

  return (
    <MuiModal
      // Disabled as this is a pass-through for ReactModal props
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classes.modal}
      onClose={onClose}
    >
      <div className={clsx(classes.content, className)} data-test-id="rex-dialog-modal">
        {showCloseButton && (
          <button
            className={clsx(classes.button, buttonClassName)}
            data-test-id="rex-modal__close-btn"
            type="button"
            aria-label="Close"
            onClick={onClose}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        )}
        {contentLabel && (
          <header className={classes.header}>
            <h2 className={classes.title} data-test-id="rex-modal-title">
              {contentLabel}
            </h2>
          </header>
        )}
        <div className={classes.body}>{children}</div>
      </div>
    </MuiModal>
  );
}

Modal.propTypes = {
  ...MuiModal.propTypes,
  buttonClassName: PropTypes.string,
  showCloseButton: PropTypes.bool,
};

Modal.defaultProps = {
  ...MuiModal.defaultProps,
  buttonClassName: null,
  showCloseButton: true,
};

export default Modal;
