import { Field, getIn } from 'formik';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

// Prop-types disabled as these are Formik props and the package does not expose them
/* eslint-disable react/prop-types */
export function renderField({ field: { name, ...field }, form }, props) {
  const error = getIn(form.errors, name);
  const touched = getIn(form.touched, name);
  /* eslint-enable react/prop-types */
  return (
    // Disabled as this is a pass-through for Formik props
    /* eslint-disable react/jsx-props-no-spreading */
    <TextField
      name={name}
      {...field}
      error={touched && !!error}
      helperText={touched && error}
      {...props}
    />
    /* eslint-enable react/jsx-props-no-spreading */
  );
}

function FormikTextField({ name, ...props }) {
  return <Field name={name}>{(formikProps) => renderField(formikProps, props)}</Field>;
}

FormikTextField.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FormikTextField;
