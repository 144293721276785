import logger from 'utils/logger';
import CMSClient from '@rex-change/rexlib/dist/CMSClient';
import { REX_SVC_CMS_HOST } from 'constants/api';
import PartnersPage from './PartnersPage.loadable';

const FIELDMETA_KEY = 'rex-partners';

const cmsClient = new CMSClient({
  baseURL: REX_SVC_CMS_HOST,
});

const fetchData = async () => {
  let content = {};

  try {
    content = await cmsClient.getKVP(FIELDMETA_KEY);
  } catch (err) {
    logger.error(err.message);
    logger.error(err.stack);
  }

  return { ...content };
};

export default {
  path: '/partners',
  exact: true,
  component: PartnersPage,
  fetchData,
};
