import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { redirect, parseRedirect } from 'helpers/redirect';
import Modal from 'components/Modal';
import './LoginModal.scss';
import LoginForm from '../LoginForm';

function LoginModal({ hash }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const closeDialog = () => {
    setIsOpen(false);

    history.replace({
      ...location,
      hash: '',
    });
  };

  const onSuccess = async () => {
    const redirectUrl = parseRedirect(location);

    closeDialog();

    if (redirectUrl && redirectUrl.startsWith('http')) {
      window.location.href = redirectUrl;
    } else if (redirectUrl) {
      redirect(redirectUrl, history);
    }
  };

  useEffect(() => {
    let mounted = true;

    const isMatch = location?.hash === hash;

    if (mounted) {
      setIsOpen(isMatch);
    }

    return () => {
      mounted = false;
    };
  }, [location?.hash, hash, setIsOpen]);

  return (
    <Modal contentLabel="Sign In" open={isOpen} onClose={closeDialog} className="rex-login-modal">
      <LoginForm onSuccess={onSuccess} />
    </Modal>
  );
}

LoginModal.propTypes = {
  hash: PropTypes.string,
};

LoginModal.defaultProps = {
  hash: '#login',
};

export default LoginModal;
