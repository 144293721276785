import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const SellVideoLanderPageLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sell-video-lander" */ 'containers/SellVideoLanderPage'),
  loading: Loading,
});

export default SellVideoLanderPageLoadable;
