export const getCookieDomain = (req = { hostname: '' }, { subdomain = true } = {}) => {
  const hostname = __CLIENT__ ? window?.location.hostname : req?.hostname;

  if (hostname === 'localhost') {
    return '';
  }

  if (subdomain) {
    const domains = hostname.split('.');
    const subdomains = domains.length > 2 ? domains.slice(1).slice(-3) : domains;
    const host = subdomains.join('.');
    return `.${host}`;
  }

  return hostname;
};

export default {
  getCookieDomain,
};
