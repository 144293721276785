import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const SellWithRexMarketingLanderPageLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: "sell-with-rex" */ 'containers/SellWithRexMarketingLanderPage'),
  loading: Loading,
});

export default SellWithRexMarketingLanderPageLoadable;
