import ENV from 'constants/env';
import VendorApi, { loadScript } from './VendorApi';

const TABLET_DEVICE = 't';
const MOBILE_DEVICE = 'm';
const DESKTOP_DEVICE = 'd';

const SELLER_PATH = 'SELLER_PATH';
const BUYER_PATH = 'BUYER_PATH';
const LISTING_PATH = 'LISTING_PATH';
const IGNORE_PATH = 'IGNORE_PATH';

const ACTION_FOCUS = 'focus';
const ACTION_LOADED = 'loaded';
const CATEGORY_FILTERED_LISTINGS_PAGE = 'filtered-listings-page';
const CATEGORY_IOI = 'ioi';
const CATEGORY_LISTING_PREVIEW = 'listing-preview';
const CATEGORY_LISTING_PREVIEW_COMPLETE = 'listing-preview-completed';
const CATEGORY_SHOWING = 'showing';
const LABEL_ADDRESS_INPUT = 'address-input';
const LABEL_BLACKNIGHT_SUBMIT = 'blacknight-submit';

const SELLER_PATHS = ['/region', '/compare', '/sell-with-rex', '/marketing-rex', '/faq/seller'];

const BUYER_PATHS = ['/buy-with-rex', '/faq/buyer'];

const IGNORE_PATHS = ['/completedpreview', '/homes'];

const EVENT_VIEW_HOME = {
  event: 'viewHome',
};

const EVENT_VIEW_LIST = {
  event: 'viewList',
  item: '1',
};

const EVENT_VIEW_ITEM = {
  event: 'viewItem',
  item: '1',
};

const EVENT_VIEW_BASKET = {
  event: 'viewBasket',
  item: [
    {
      id: '1',
      price: '0',
      quantity: 1,
    },
  ],
};

const EVENT_TRANSACTION = {
  event: 'trackTransaction',
  id: null,
  item: [
    {
      id: '1',
      price: '0',
      quantity: 1,
    },
  ],
};

const EVENT_MANUAL_FLUSH = {
  event: 'manualFlush',
};

const EVENT_FLUSH = {
  event: 'flushEvents',
};

const isTablet = (ua) => /iPad/.test(ua);
const isMobile = (ua) => /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(ua);

const getDeviceType = (ua) => {
  if (isTablet(ua)) {
    return TABLET_DEVICE;
  }

  if (isMobile(ua)) {
    return MOBILE_DEVICE;
  }

  return DESKTOP_DEVICE;
};

const queueCriteoTag = (evts) => {
  window.criteo_q.push(...evts);
};

const getTransactionEvent = (id, rexUrl) => {
  const event = { ...EVENT_TRANSACTION, id };

  if (rexUrl) {
    event.item[0].id = rexUrl;
  }

  return event;
};

const getBuyerItemEvent = (item) => ({ ...EVENT_VIEW_ITEM, item });

const getBuyerBasketEvent = (item) => {
  const event = { ...EVENT_VIEW_BASKET };
  event.item = [
    {
      price: '0',
      quantity: 1,
      id: item,
    },
  ];
  return event;
};

const getBuyerListEvent = (listings) => {
  const items = listings.map((listing) => listing.rexUrl);
  return { ...EVENT_VIEW_LIST, items };
};

const getTimestamp = () => new Date().getTime();
const getRexUrl = (path) => path.split('/').pop();

const isSellerPath = (path) => SELLER_PATHS.find((sellerPath) => path.startsWith(sellerPath));
const isBuyerPath = (path) => BUYER_PATHS.includes((buyerPath) => path.startsWith(buyerPath));
const isIgnorePath = (path) => IGNORE_PATHS.includes((ignorePath) => path.startsWith(ignorePath));
const isListingPath = (path) => path.startsWith('/listing') && path.indexOf('statistic') === -1;

const isListingPreviewCompleted = (category) => category === CATEGORY_LISTING_PREVIEW_COMPLETE;
const isBlacknightSubmit = (label) => label === LABEL_BLACKNIGHT_SUBMIT;
const isListingPreviewAddressFocus = (category, label, action) =>
  category === CATEGORY_LISTING_PREVIEW && label === LABEL_ADDRESS_INPUT && action === ACTION_FOCUS;
const isBuyerTransactionEvent = (category) =>
  category === CATEGORY_SHOWING || category === CATEGORY_IOI;

const isFilteredListingLoaded = (category, action) =>
  category === CATEGORY_FILTERED_LISTINGS_PAGE && action === ACTION_LOADED;

const isSubmitAction = (action) => action.indexOf('submit') > -1;

const getPathType = (path) => {
  if (isIgnorePath(path)) {
    return IGNORE_PATH;
  }

  if (isListingPath(path)) {
    return LISTING_PATH;
  }

  if (isSellerPath(path)) {
    return SELLER_PATH;
  }

  if (isBuyerPath(path)) {
    return BUYER_PATH;
  }

  return null;
};

class CriteoOneTag extends VendorApi {
  static buyerBasket = false;

  constructor(options = { src: '//static.criteo.net/js/ld/ld.js' }) {
    super('criteo-one-tag', 'criteo_q', options);
  }

  _init() {
    return loadScript(this.options.src).then(() => {
      this.createEvents();
    });
  }

  createEvents() {
    const type = getDeviceType(navigator.ua);
    this.sellerAccount = ENV.REX_CRITEO_SELLER_ACCOUNT;
    this.buyerAccount = ENV.REX_CRITEO_BUYER_ACCOUNT;

    this.eventDeviceType = {
      event: 'setSiteType',
      type,
    };

    this.eventSellerAccount = {
      event: 'setAccount',
      account: ENV.REX_CRITEO_SELLER_ACCOUNT,
    };

    this.eventBuyerAccount = {
      event: 'setAccount',
      account: ENV.REX_CRITEO_BUYER_ACCOUNT,
    };
  }

  getListingPageEvents(path) {
    let events = [];
    const parts = path.split('/');

    if (parts && parts.length >= 3) {
      events = [this.eventBuyerAccount, this.eventDeviceType, getBuyerItemEvent(parts[2])];
    }

    return events;
  }

  getSellerPageEvents() {
    return [this.eventSellerAccount, this.eventDeviceType, EVENT_VIEW_LIST];
  }

  getBuyerPageEvents() {
    return [this.eventBuyerAccount, this.eventDeviceType, EVENT_VIEW_HOME];
  }

  getPageViewEvents(path) {
    let events = [];

    const pathType = getPathType(path);

    switch (pathType) {
      case IGNORE_PATH:
        break;
      case LISTING_PATH:
        events = this.getListingPageEvents(path);
        break;
      case BUYER_PATH:
        events = this.getBuyerPageEvents();
        break;
      case SELLER_PATH:
        events = this.getSellerPageEvents();
        break;
      default:
        events = [this.eventSellerAccount, this.eventDeviceType, EVENT_VIEW_HOME];
    }

    return events;
  }

  _pageView(event, params) {
    let { path } = params;

    if (path.indexOf('/') !== 0) {
      path = `/${path}`;
    }

    const events = this.getPageViewEvents(path);
    if (events && events.length) {
      queueCriteoTag(events);
      return events;
    }

    return null;
  }

  getTrackEvents(action, properties) {
    let events = [];

    if (!properties) {
      return events;
    }

    const { category, label, listings, isServiceArea } = properties;
    const rexUrl = getRexUrl(window.location.pathname);
    const transactionId = getTimestamp();

    if (isServiceArea && isListingPreviewCompleted(category)) {
      events = [
        EVENT_MANUAL_FLUSH,
        this.eventSellerAccount,
        EVENT_VIEW_ITEM,
        EVENT_FLUSH,
        this.eventSellerAccount,
        EVENT_VIEW_BASKET,
        EVENT_FLUSH,
      ];
    } else if (isBlacknightSubmit(label)) {
      events = [
        this.eventSellerAccount,
        this.eventDeviceType,
        getTransactionEvent(transactionId),
        EVENT_FLUSH,
      ];
    } else if (isListingPreviewAddressFocus(category, label, action)) {
      events = [this.eventSellerAccount, this.eventDeviceType, EVENT_VIEW_ITEM];
    } else if (isBuyerTransactionEvent(category)) {
      events = [this.eventBuyerAccount, this.eventDeviceType];

      if (isSubmitAction(action)) {
        events.push(getTransactionEvent(transactionId, rexUrl), EVENT_FLUSH);
        // after a submission, allow basket events to fire again
        CriteoOneTag.buyerBasket = false;
      } else if (!CriteoOneTag.buyerBasket) {
        events.push(getBuyerBasketEvent(rexUrl));
        CriteoOneTag.buyerBasket = true;
      } else {
        // only fire one basket event, so clear future requests
        events = [];
      }
    } else if (isFilteredListingLoaded(category, action)) {
      events = [this.eventBuyerAccount, this.eventDeviceType, getBuyerListEvent(listings)];
    }

    return events;
  }

  _track(...args) {
    const events = this.getTrackEvents(...args);
    if (events && events.length) {
      queueCriteoTag(events);
      return events;
    }

    return null;
  }
}

export default CriteoOneTag;
