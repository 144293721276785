export const states = [
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'District of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
];

export function asNameString(useAnd = true) {
  const currentStates = states.slice();
  if (useAnd) {
    const lastState = currentStates[currentStates.length - 1];
    currentStates[currentStates.length - 1] = {
      name: `and ${lastState.name}`,
      abbreviation: `and ${lastState.abbreviation}`,
    };
  }

  return currentStates.map((state) => state.name).join(', ');
}

export function asAbbreviationString(useAnd = true) {
  const currentStates = states.slice();
  if (useAnd) {
    const lastState = currentStates[currentStates.length - 1];
    currentStates[currentStates.length - 1] = {
      name: `and ${lastState.name}`,
      abbreviation: `and ${lastState.abbreviation}`,
    };
  }

  return currentStates.map((state) => state.abbreviation).join(', ');
}
