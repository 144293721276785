import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect as RouteRedirect } from 'react-router';

function Redirect({ from, to, status }) {
  return (
    <Route
      render={({ staticContext }) => {
        // there is no `staticContext` on the client, so
        // we need to guard against that here
        if (staticContext) {
          // eslint-disable-next-line no-param-reassign
          staticContext.status = status;
        }
        return <RouteRedirect from={from} to={to} />;
      }}
    />
  );
}

Redirect.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string.isRequired,
  status: PropTypes.number,
};

Redirect.defaultProps = {
  from: '',
  status: 302,
};

export default Redirect;
