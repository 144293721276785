import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import rexCookies from '@rex-change/rexlib/dist/rexCookies';
import getPhoneNumber from 'helpers/getPhoneNumber/getPhoneNumber';
import { PHONE_NUMBER } from 'constants/contact';
import { getUtmParams } from 'helpers/utmParamsHelpers';

function fetchPhoneNumberFromRequest() {
  const { source, medium, campaign } = getUtmParams();
  const initialReferrer = rexCookies.isSet('initialReferrer') && rexCookies.get('initialReferrer');
  const referrer = initialReferrer || document.referrer || '';
  rexCookies.set('initialReferrer', referrer);

  return getPhoneNumber({ source, medium, campaign, referrer });
}

export const GlobalDataContext = createContext({});

class GlobalDataContextProvider extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  state = { phoneNumber: PHONE_NUMBER };

  componentDidMount() {
    if (__CLIENT__) {
      fetchPhoneNumberFromRequest().then((phoneNumber) => this.setState({ phoneNumber }));
    }
  }

  render() {
    const { children } = this.props;
    const { phoneNumber } = this.state;

    return (
      <GlobalDataContext.Provider value={{ phoneNumber }}>{children}</GlobalDataContext.Provider>
    );
  }
}

export default GlobalDataContextProvider;
