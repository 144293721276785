import React from 'react';
import { Helmet } from 'react-helmet';

function Preload() {
  return (
    <Helmet>
      <link
        rel="preload"
        href="/assets/fonts/Avenir/370028_0_unhinted_0.woff2"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/assets/fonts/Avenir/370028_1_unhinted_0.woff2"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/assets/fonts/Avenir/370028_2_unhinted_0.woff2"
        as="font"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="/assets/fonts/Avenir/370028_3_unhinted_0.woff2"
        as="font"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
}

export default Preload;
