import CMSClient from '@rex-change/rexlib/dist/CMSClient';
import { REX_SVC_CMS_HOST } from 'constants/api';
import logger from 'utils/logger';
import LoadableTestimonialsPage from './TestimonialsPage.loadable';
import DEFAULT_CONTENT from './content.cms.json';

const FIELDMETA_KEY = 'rex-testimonials';

const cmsClient = new CMSClient({
  baseURL: REX_SVC_CMS_HOST,
});

async function fetchData() {
  let content;

  try {
    content = await cmsClient.getKVP(FIELDMETA_KEY);
  } catch (err) {
    logger.error('Error getting testimonials content');
    logger.error(err.message);
    logger.error(err.stack);
    content = DEFAULT_CONTENT;
  }

  return { ...content };
}

export default {
  path: '/testimonials',
  exact: true,
  component: LoadableTestimonialsPage,
  fetchData,
};
