import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const CityListingSearchPageLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: "city-listing-search" */ 'containers/CityListingSearchPage'),
  loading: Loading,
});

export default CityListingSearchPageLoadable;
