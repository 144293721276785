import ListingClient from '@rex-change/rexlib/dist/ListingClient';
import GeoClient from '@rex-change/rexlib/dist/GeoClient';
import RexAPI from '@rex-change/rexlib/dist/RexAPI';
import { LISTING_STATUS_INACTIVE } from 'constants/listing';
import logger from 'utils/logger';
import {
  REX_SVC_REX_APP_HOST,
  REX_SVC_CLIENT_REX_AIR_HOST,
  REX_SVC_LISTING_QUERY_HOST,
} from 'constants/api';
import LoadableListingDisplay from './ListingDisplayPage.loadable';

const listingClient = new ListingClient({
  baseURL: REX_SVC_REX_APP_HOST,
});

const listingQueryClient = new ListingClient({
  baseURL: REX_SVC_LISTING_QUERY_HOST,
});

const geoClient = new GeoClient({
  baseURL: REX_SVC_REX_APP_HOST,
});

const rexAirApi = new RexAPI({
  baseURL: REX_SVC_CLIENT_REX_AIR_HOST,
});

async function fetchData({ rexUrl }, search) {
  let listing;
  let openHouses;
  let nearbyListings;
  let searchStates;
  let isListingError = false;
  let isFromDashboard = false;

  if (search) {
    const queryParams = new URLSearchParams(search);
    if (queryParams.get('dashboard') === 'true') {
      isFromDashboard = true;
    }
  }

  try {
    listing = await listingClient.getListingByURL(rexUrl);

    listing.images = listing.images.sort((a, b) => a.order - b.order);
  } catch (err) {
    isListingError = true;
    throw new Error(`Listing Not Found: ${rexUrl}`);
  }

  if (listing.status === LISTING_STATUS_INACTIVE) {
    isListingError = true;
    throw new Error(`Listing is inactive: ${rexUrl}`);
  }

  try {
    openHouses = await listingClient.getOpenHouses(listing.guid);
  } catch (err) {
    logger.warn(err.message || err);
    openHouses = [];
  }

  try {
    nearbyListings = await listingQueryClient.getNearbyListings(listing.guid);
  } catch (err) {
    logger.warn(err.message || err);
    nearbyListings = {
      content: [],
    };
  }

  try {
    searchStates = await geoClient.getStates();
  } catch (err) {
    logger.warn(err.message || err);
    searchStates = [];
  }

  try {
    const response = await rexAirApi.get(`/api/${listing.guid}/compressed_videos`);
    const responseData = response?.data;
    listing.images = [...listing.images, ...responseData];
  } catch (error) {
    logger.error(error.message || error);
  }

  return {
    listing,
    openHouses,
    nearbyListings,
    searchStates,
    isListingError,
    isFromDashboard,
    marketingData: {
      listingGuid: listing?.guid,
      listingPrice: listing?.price,
    },
  };
}

export default {
  path: '/listing/:rexUrl',
  exact: true,
  component: LoadableListingDisplay,
  fetchData: ({ match: { params }, location: { search } }) => fetchData(params, search),
};
