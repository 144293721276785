import React from 'react';
import { Box, Typography, Paper, makeStyles, Slide } from '@material-ui/core';
import { useRexUser } from '@rex-change/brix';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: theme.spacing(1, 0),
    zIndex: theme.zIndex.appBar + 1,
  },
  paper: {
    background: theme.palette.error.dark,
    color: theme.palette.error.contrastText,
  },
}));
export function AdminBanner() {
  const { isAdmin } = useRexUser();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Slide direction="up" in={isAdmin} mountOnEnter unmountOnExit>
        <Paper className={classes.paper} elevation={4}>
          <Typography tvariant="h5" align="center">
            You are logged in as the user.
          </Typography>
          <Typography align="center">
            Please Logout of the User Account once your are done.
          </Typography>
        </Paper>
      </Slide>
    </Box>
  );
}

export default AdminBanner;
