import axios from 'axios';
import { PHONE_NUMBER } from '../../constants/contact';
import { REX_SVC_CLIENT_PHONE_TRACKING_HOST } from '../../constants/api';

const USA_COUNTRY_PHONE_PREFIX = '+1';

async function getPhoneNumber({ source, medium, campaign, referrer }) {
  const url = `${REX_SVC_CLIENT_PHONE_TRACKING_HOST}/phone-tracking/phonenumber`;
  const payload = {
    source,
    medium,
    campaign,
    referrer,
  };

  try {
    return await axios
      .post(url, payload)
      .then(
        (result) =>
          (result.data &&
            result.data.phone_number &&
            result.data.phone_number.replace(USA_COUNTRY_PHONE_PREFIX, '')) ||
          PHONE_NUMBER,
      );
  } catch (error) {
    return PHONE_NUMBER;
  }
}

export default getPhoneNumber;
