import component from './InsurancePage.loadable';
import defaultContent from './cms.json';

const fetchData = async () => ({
  content: defaultContent,
});

export default {
  path: '/service/insurance',
  exact: true,
  component,
  fetchData,
};
