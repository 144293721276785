import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { string as yupString, object as yupObject } from 'yup';
import { Formik, Form, Field } from 'formik';
import LeadClient from '@rex-change/rexlib/dist/LeadClient';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import formatPhone from '@rex-change/rexlib/dist/formatPhone';
import { PHONE_NUMBER, EMAIL_ADDRESS } from 'constants/contact';
import { GlobalDataContext } from 'contexts/GlobalDataContext/GlobalDataContext';
import { appendSessionAttributesToUrl } from 'helpers/sessionAttributeHelpers';
import './NewAreaSignupForm.scss';

export class NewAreaSignupForm extends Component {
  static formSchema = yupObject().shape({
    email: yupString()
      .email('A valid email address is required')
      .required('An email address is required.'),
    cityState: yupString().required('Please enter your city and state.'),
    name: yupString().required('Please enter your name.'),
    phoneNumber: yupString(),
  });

  static initialValues = {
    email: '',
    name: '',
    cityState: '',
    phone: '',
  };

  id = uuid();

  leadClient = new LeadClient({
    baseURL: REX_SVC_REX_APP_HOST,
  });

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    phoneNumber: PropTypes.string,
  };

  static defaultProps = {
    phoneNumber: PHONE_NUMBER,
  };

  onSubmit = (values, actions) => {
    const { onSuccess } = this.props;

    this.submitLead(values)
      .then(onSuccess)
      .catch((err) => {
        actions.setError({
          form: err.message,
        });
      });

    actions.setSubmitting(false);
  };

  submitLead(values) {
    const leadType = 'NEW_AREA_LISTING_REQUEST';
    const source = appendSessionAttributesToUrl(window.location.href);
    const { email } = values;

    return this.leadClient.saveLead(leadType, email, source, values);
  }

  render() {
    const { phoneNumber } = this.props;

    return (
      <Formik
        validationSchema={NewAreaSignupForm.formSchema}
        initialValues={NewAreaSignupForm.initialValues}
        onSubmit={this.onSubmit}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className="rex-new-area-signup">
            <div className="rex-new-area-signup__city-state">
              <label
                className="rex-new-area-signup__city-state__label"
                htmlFor={`${this.id}-city-state`}
              >
                Enter Your City/State*
              </label>
              <div className="rex-new-area-signup__city-state__container">
                <Field
                  className="rex-new-area-signup__city-state__input"
                  type="text"
                  name="cityState"
                  placeholder="Woodland Hills, CA"
                  disabled={isSubmitting}
                  id={`${this.id}-city-state`}
                />
              </div>
              <div className="rex-new-area-signup__city-state__footer">
                {errors.cityState && touched.cityState && (
                  <div key="cityState" className="rex-new-area-signup__city-state__error">
                    {errors.cityState}
                  </div>
                )}
              </div>
            </div>
            <div className="rex-new-area-signup__name">
              <label className="rex-new-area-signup__name__label" htmlFor={`${this.id}-name`}>
                Enter Your Name*
              </label>
              <div className="rex-new-area-signup__name__container">
                <Field
                  className="rex-new-area-signup__name__input"
                  type="text"
                  name="name"
                  placeholder="John Doe"
                  disabled={isSubmitting}
                  id={`${this.id}-name`}
                />
              </div>
              <div className="rex-new-area-signup__name__footer">
                {errors.name && touched.name && (
                  <div key="name" className="rex-new-area-signup__name__error">
                    {errors.name}
                  </div>
                )}
              </div>
            </div>
            <div className="rex-new-area-signup__email">
              <label className="rex-new-area-signup__email__label" htmlFor={`${this.id}-email`}>
                Enter Your Email*
              </label>
              <div className="rex-new-area-signup__email__container">
                <Field
                  className="rex-new-area-signup__email__input"
                  type="email"
                  name="email"
                  placeholder="john@email.com"
                  disabled={isSubmitting}
                  id={`${this.id}-email`}
                />
              </div>
              <div className="rex-new-area-signup__email__footer">
                {errors.email && touched.email && (
                  <div key="email" className="rex-new-area-signup__email__error">
                    {errors.email}
                  </div>
                )}
              </div>
            </div>
            <div className="rex-new-area-signup__phone">
              <label className="rex-new-area-signup__phone__label" htmlFor={`${this.id}-phone`}>
                Phone Number
              </label>
              <div className="rex-new-area-signup__phone__container">
                <Field
                  className="rex-new-area-signup__phone__input"
                  type="tel"
                  name="phone"
                  placeholder="3107459784"
                  disabled={isSubmitting}
                  id={`${this.id}-phone`}
                />
              </div>
              <div className="rex-new-area-signup__phone__footer">
                {errors.phone && touched.phone && (
                  <div key="phone" className="rex-new-area-signup__phone__error">
                    {errors.phone}
                  </div>
                )}
              </div>
            </div>
            <div className="rex-new-area-signup__actions">
              <button className="rex-new-area-signup__submit" type="submit" disabled={isSubmitting}>
                Submit
              </button>
            </div>
            <div className="rex-new-area-signup__footer">
              {errors.form && (
                <div key="form" className="rex-new-area-signup__error">
                  There was an issue submitting your information, please try again or feel free to
                  email us at{' '}
                  <a href={`mailto:${EMAIL_ADDRESS}?subject=Hello, I'm interested in selling.`}>
                    {EMAIL_ADDRESS}
                  </a>{' '}
                  or call us at <a href={`tel:${phoneNumber}`}>{formatPhone(phoneNumber)}</a>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const WithGlobalData = (props) => (
  <GlobalDataContext.Consumer>
    {({ phoneNumber }) => (
      // Disabled as this is a pass-through for non-context props
      // eslint-disable-next-line react/jsx-props-no-spreading
      <NewAreaSignupForm {...props} phoneNumber={phoneNumber} />
    )}
  </GlobalDataContext.Consumer>
);

export default WithGlobalData;
