import React, { useEffect } from 'react';
import { Switch, useLocation } from 'react-router';
import { Route } from 'react-router-dom';
import routes from 'constants/routes';
import { metrics, PropTypes as MetricsPropTypes } from 'react-metrics';
import metricsConfig, { options } from 'helpers/analytics/metrics.config';
import Preload from 'components/Preload';
import { VisitorProvider, RexUserProvider } from '@rex-change/brix';
import { UIManagerProvider } from 'contexts/UIManager';
import GlobalDataContextProvider from 'contexts/GlobalDataContext';
import LoginModal from 'components/LoginModal';
import TokenErrorModal from 'components/TokenErrorModal';
import ResetPasswordModal from 'components/ResetPasswordModal';
import ForgotPasswordModal from 'components/ForgotPasswordModal';
import RegisterModal from 'components/RegisterModal';
import NewAreaSignupModal from 'components/NewAreaSignupModal';
import PrivateRoute from 'components/PrivateRoute';
import { Helmet } from 'react-helmet';
import { populateUtmParams } from 'helpers/utmParamsHelpers';
import { populateSessionAttributes } from 'helpers/sessionAttributeHelpers';
import toggleTestCookie from 'utils/automation';
import AdminBanner from 'components/AdminBanner';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import { getCookieDomain } from 'utils/cookies';
import pkg from '../package.json';
import './App.scss';

function MainApp(props, { metrics: appMetrics }) {
  appMetrics.track('version', {
    category: 'version',
    label: pkg.version,
    nonInteraction: true,
  });

  return (
    <VisitorProvider client={__CLIENT__} cookieDomain={getCookieDomain()}>
      <RexUserProvider apiHost={REX_SVC_REX_APP_HOST}>
        <GlobalDataContextProvider>
          <Preload />
          <UIManagerProvider>
            <div className="content rex-page">
              <Helmet>
                <title>REX Real Estate - A Smarter Way to Buy and Sell Homes</title>
                <meta
                  property="og:title"
                  content="REX Real Estate - A Smarter Way to Buy and Sell Homes"
                />
                <meta
                  name="description"
                  content="Buy or sell your house with Rex Homes, a full-service real estate broker. We use AI and machine learning to streamline the home sales process. Get started today!"
                />
                <meta
                  property="og:description"
                  content="Buy or sell your house with Rex Homes, a full-service real estate broker. We use AI and machine learning to streamline the home sales process. Get started today!"
                />
                <meta property="og:type" content="website" />
                <meta
                  property="og:image"
                  content="/assets/images/rex-share-logo.jpg"
                />
                <meta property="fb:app_id" content="453949648104394" />
                <meta name="format-detection" content="telephone=no" />
              </Helmet>
              <AdminBanner />
              <Switch>
                {routes.map(
                  // Disabled as this is a pass-through for route props
                  /* eslint-disable react/jsx-props-no-spreading */
                  (route) =>
                    route.requiresAccount ? (
                      <PrivateRoute {...route} key={route.path} />
                    ) : (
                      <Route {...route} key={route.path} />
                    ),
                  /* eslint-enable react/jsx-props-no-spreading */
                )}
              </Switch>
              <LoginModal hash="#login" />
              <TokenErrorModal hash="#token-error" />
              <ResetPasswordModal hash="#reset-password" />
              <ForgotPasswordModal hash="#forgot-password" />
              <RegisterModal hash="#register" />
              <NewAreaSignupModal hash="#new-area-signup" />
            </div>
          </UIManagerProvider>
        </GlobalDataContextProvider>
      </RexUserProvider>
    </VisitorProvider>
  );
}

MainApp.contextTypes = {
  metrics: MetricsPropTypes.metrics,
};

const DecoratedApp = metrics(metricsConfig, options)(MainApp);

function App() {
  const location = useLocation();
  useEffect(() => {
    if (location) {
      toggleTestCookie(location);
    }
  }, [location]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'optimize.activate' });
  }, []);

  useEffect(() => {
    populateUtmParams();
    populateSessionAttributes();
  }, []);

  return <Route path="/" component={DecoratedApp} />;
}

export default App;
