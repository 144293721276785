import ENV from 'constants/env';
import VendorApi, { loadScript } from './VendorApi';

const LABEL_BLACKNIGHT_SUBMIT = 'blacknight-submit';
const IGNORE_PATH = 'ignore';
const BUYER_PATH = 'buyer';
const SELLER_PATH = 'seller';
const LDP_PATH = 'ldp';
const BUYER_PATHS = ['/buy-with-rex', '/faq/buyer', '/service/buyerrebate'];
const LDP_PATHS = ['/listing'];
const IGNORE_PATHS = [
  '/completedpreview',
  '/homes',
  '/control-panel',
  '/indication-of-interest',
  '/service/rexhomeloans',
  '/about',
  '/testimonials',
  '/careers',
  '/contact',
  '/rex-social-mission',
];

const isBuyerPath = (path) => BUYER_PATHS.find((buyerPath) => path.startsWith(buyerPath));
const isLdpPath = (path) => LDP_PATHS.find((buyerPath) => path.startsWith(buyerPath));
const isIgnorePath = (path) => IGNORE_PATHS.find((ignorePath) => path.startsWith(ignorePath));
const isBlacknightSubmit = (label) => label === LABEL_BLACKNIGHT_SUBMIT;

const getPathType = (path) => {
  if (isIgnorePath(path)) {
    return IGNORE_PATH;
  }

  if (isLdpPath(path)) {
    return LDP_PATH;
  }

  if (isBuyerPath(path)) {
    return BUYER_PATH;
  }
  return SELLER_PATH;
};

class TheTradeDeskTag extends VendorApi {
  constructor(options = { src: '//js.adsrvr.org/up_loader.1.1.0.js' }) {
    super('the-trade-desk-tag', null, options);
  }

  _init() {
    return loadScript(this.options.src).then(() => {
      this.loadIds();
    });
  }

  loadIds() {
    this.sellerRetargeting = ENV.REX_TRADEDESK_SELLER_RETARGETING;
    this.sellerConversion = ENV.REX_TRADEDESK_SELLER_CONVERSION;
    this.buyer = ENV.REX_TRADEDESK_BUYER;
    this.id = ENV.REX_TRADEDESK_ID;
  }

  firePixel(pxlType) {
    const { id } = this;
    const { ttd_dom_ready: ttdDomReady, TTDUniversalPixelApi } = window;
    ttdDomReady(() => {
      if (typeof TTDUniversalPixelApi === 'function') {
        const universalPixelApi = new TTDUniversalPixelApi();
        universalPixelApi.init(id, [pxlType], 'https://insight.adsrvr.org/track/up');
      }
    });
  }

  getPageViewType(path) {
    const pathType = getPathType(path);
    let pxlType = null;
    switch (pathType) {
      case IGNORE_PATH:
        break;
      case LDP_PATH:
        pxlType = this.buyer;
        break;
      case BUYER_PATH:
        break;
      case SELLER_PATH:
      default:
        pxlType = this.sellerRetargeting;
        break;
    }
    return pxlType;
  }

  _pageView(event, params) {
    let { path } = params;

    if (path.indexOf('/') !== 0) {
      path = `/${path}`;
    }

    const pxlType = this.getPageViewType(path);
    if (pxlType) {
      this.firePixel(pxlType);
    }
    return pxlType;
  }

  getEventType(action, properties) {
    let pxlType = null;

    if (!properties) {
      return pxlType;
    }

    const { label } = properties;

    if (isBlacknightSubmit(label)) {
      pxlType = this.sellerConversion;
    }
    return pxlType;
  }

  _track(...args) {
    const pxlType = this.getEventType(...args);
    if (pxlType) {
      this.firePixel(pxlType);
    }
    return pxlType;
  }
}

export default TheTradeDeskTag;
