import { GeoClient } from '@rex-change/rexlib/dist/GeoClient';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import logger from 'utils/logger';
import LoadableListingSearchPage from './ListingSearchPage.loadable';

const geoClient = new GeoClient({ baseURL: REX_SVC_REX_APP_HOST });

export async function fetchData() {
  let regions = [];
  try {
    regions = await geoClient.getRegions();

    regions =
      Array.isArray(regions) &&
      regions.sort((a, b) => {
        if (!a.available && b.available) {
          return 1;
        }

        if (a.available && !b.available) {
          return -1;
        }

        return a.name.localeCompare(b.name);
      });
  } catch (error) {
    logger.warn('get regions failed', error?.message ?? error);

    regions = [];
  }

  return { regions };
}

export default {
  path: '/homes',
  exact: true,
  component: LoadableListingSearchPage,
  fetchData,
};
