import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { string as yupString, object as yupObject } from 'yup';
import { Formik, Form } from 'formik';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AuthClient } from '@rex-change/rexlib/dist/AuthClient';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import FormikTextField from '../HumanlessListingFlow/Inputs/FormikTextField';

const useStyles = makeStyles((theme) => ({
  field: {
    margin: theme.spacing(1, 0),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

const authClient = new AuthClient({
  baseURL: REX_SVC_REX_APP_HOST,
});

function ForgotPasswordForm({ onSuccess, toSignIn, initialEmailAddress }) {
  const id = uuid();
  const classes = useStyles();
  const history = useHistory();
  const signInClick =
    toSignIn ||
    (() => {
      const { location } = history;
      history.push({
        ...location,
        hash: '#login',
      });
    });

  const initialValues = {
    ...ForgotPasswordForm.initialValues,
    email: initialEmailAddress ?? ForgotPasswordForm.initialValues.email,
  };

  const onSubmit = async ({ email }, { setErrors, setSubmitting }) => {
    try {
      await authClient.forgotPassword(email);

      onSuccess();
    } catch (err) {
      setErrors({
        form: err.message,
      });
    }

    setSubmitting(false);
  };

  return (
    <Formik
      validationSchema={ForgotPasswordForm.formSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form className={classes.wrapper}>
          <Typography>Enter your email and we'll send you a link to set your password.</Typography>
          <FormikTextField
            id={`${id}-email`}
            name="email"
            type="email"
            label="Email"
            className={classes.field}
            fullWidth
            variant="outlined"
            data-rex-id="forgot-password-email-field"
          />
          <Button
            type="submit"
            disabled={isSubmitting}
            className={classes.button}
            fullWidth
            color="primary"
            variant="contained"
            data-rex-id="forgot-password-btn"
          >
            Send
          </Button>
          {errors.form && <Alert severity="error">{errors.form}</Alert>}
          <Typography>
            Know your password?
            <Button type="button" onClick={signInClick} disabled={isSubmitting}>
              Sign In
            </Button>
          </Typography>
        </Form>
      )}
    </Formik>
  );
}

ForgotPasswordForm.propTypes = {
  onSuccess: PropTypes.func,
  toSignIn: PropTypes.func,
  initialEmailAddress: PropTypes.string,
};

ForgotPasswordForm.defaultProps = {
  onSuccess: () => {},
  toSignIn: null,
  initialEmailAddress: null,
};

ForgotPasswordForm.formSchema = yupObject().shape({
  email: yupString()
    .email('A valid email address is required')
    .required('An email address is required.'),
});

ForgotPasswordForm.initialValues = {
  email: '',
};

export default ForgotPasswordForm;
