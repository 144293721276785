import { REX_NO_TRACK_COOKIE } from 'constants/cookies';
import logger from 'utils/logger';

export const loadScript = (src, async = true, defer = true, id) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.onload = resolve;
    script.onerror = (error) => reject(error);

    script.src = src;
    script.async = async;
    script.defer = defer;
    script.id = id;
    const [currScript] = document.getElementsByTagName('script');
    currScript.parentNode.insertBefore(script, currScript);
  });

export const noTrack = () => {
  const { rexCookies } = require('@rex-change/rexlib/dist/rexCookies'); // eslint-disable-line global-require

  return !!(rexCookies && rexCookies.get(REX_NO_TRACK_COOKIE));
};

/* eslint-disable no-underscore-dangle */
class VendorApi {
  constructor(name, libName, options) {
    this.options = options;
    this.name = name;
    this.libName = libName;
    this.loaded = false;
    this.isEnabled = !noTrack();
    this.enqueuedEvents = [];
    if (__CLIENT__) {
      this.load();
    }
  }

  pageView(...args) {
    if (!this.isEnabled) {
      return Promise.reject(new Error('Tracking Disabled'));
    }

    return new Promise((resolve) => {
      const doPageView = () => {
        if (this._pageView) {
          resolve(this._pageView(...args));
        } else {
          resolve();
        }
      };

      if (this.loaded) {
        doPageView();
      } else {
        this.enqueuedEvents.push(doPageView);
      }
    });
  }

  track(...args) {
    if (!this.isEnabled) {
      return Promise.reject(new Error('Tracking Disabled'));
    }

    return new Promise((resolve) => {
      const doTrack = () => {
        if (this._track) {
          resolve(this._track(...args));
        } else {
          resolve();
        }
      };

      if (this.loaded) {
        doTrack();
      } else {
        this.enqueuedEvents.push(doTrack);
      }
    });
  }

  user(...args) {
    if (!this.isEnabled) {
      return Promise.reject(new Error('Tracking Disabled'));
    }

    return new Promise((resolve) => {
      const doUser = () => {
        if (this._user) {
          resolve(this._user(...args));
        } else {
          resolve();
        }
      };

      if (this.loaded) {
        doUser();
      } else {
        this.enqueuedEvents.push(doUser);
      }
    });
  }

  load() {
    if (!this.isEnabled) {
      return;
    }

    if (!this.loaded && this._init) {
      this._init()
        .then((lib) => {
          this.lib = lib;
          this.loaded = true;
          this.isEnabled = true;
          this.enqueuedEvents.forEach((event) => event());
          this.enqueuedEvents = [];
        })
        .catch((error) => {
          logger.log(`Failed to load analytics ${this.name}: `, error);
          this.enqueuedEvents = [];
          this.isEnabled = false;
        });
    }
  }
}
/* eslint-enable no-underscore-dangle */

export default VendorApi;
