import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { UserClient } from '@rex-change/rexlib/dist/UserClient';
import { AuthClient } from '@rex-change/rexlib/dist/AuthClient';
import { ListingClient } from '@rex-change/rexlib/dist/ListingClient';
import { rexSession } from '@rex-change/rexlib/dist/rexSession';
import { rexCookies } from '@rex-change/rexlib/dist/rexCookies';
import { REX_SVC_REX_APP_HOST } from 'constants/api';

export const ADMIN_LOGIN_KEY = 'rex-admin-login';

export const UserContext = createContext({
  loggedIn: false,
  loginPromise: null,
  isAdmin: false,
  user: {
    firstName: 'Guest',
  },
  favoritedListings: [],
  updateProfile: () => {},
  claimListing: () => {},
  onLoggedIn: () => {},
});

export class UserContextProvider extends Component {
  state = {
    isInitialized: false,
    isAdmin: false,
    loggedIn: false,
    user: {},
    favoritedListings: [],
  };

  userClient = new UserClient({
    baseURL: REX_SVC_REX_APP_HOST,
  });

  auth = new AuthClient({
    baseURL: REX_SVC_REX_APP_HOST,
  });

  listingClient = new ListingClient({
    baseURL: REX_SVC_REX_APP_HOST,
  });

  static propTypes = {
    children: PropTypes.node,
  };

  static defaultProps = {
    children: null,
  };

  componentDidMount() {
    this.updateProfile()
      .catch(() => {})
      // Finally
      .then(() => this.setState({ isInitialized: true }));
  }

  logoff = () => {
    rexSession.clear();
    rexCookies.remove(ADMIN_LOGIN_KEY);
    this.setState({
      loggedIn: false,
      user: {},
      isAdmin: false,
    });
  };

  updateProfile = () => Promise.all([this.updateUser(), this.updateFavorites()]);

  updateFavorites = () =>
    this.userClient.getFavorites().then(({ data }) => {
      this.setState({ favoritedListings: data });
    });

  updateUser = () =>
    this.userClient.getProfile().then((data) => {
      const isAdmin = !!rexCookies.get(ADMIN_LOGIN_KEY);
      this.setState({
        loggedIn: true,
        user: data,
        isAdmin,
      });
    });

  isListingFavorited = (listingGuid) => {
    const { favoritedListings } = this.state;
    return !!favoritedListings.find((listing) => listing.guid === listingGuid);
  };

  addListingToFavorites = (listingGuid) =>
    this.userClient.addFavorite(listingGuid).then(() => this.updateFavorites());

  removeListingFromFavorites = (listingGuid) =>
    this.userClient.removeFavorite(listingGuid).then(() => this.updateFavorites());

  toggleListingFavorite = (listingGuid) =>
    this.isListingFavorited(listingGuid)
      ? this.removeListingFromFavorites(listingGuid)
      : this.addListingToFavorites(listingGuid);

  claimListing = (guid, path) => {
    const { loggedIn } = this.state;

    if (loggedIn) {
      return this.listingClient.claim(guid, path);
    }

    return Promise.reject();
  };

  login = async (creds) => {
    await this.auth.login(creds);
    await this.updateProfile();
    const { user, isAdmin } = this.state;

    this.setState({ loggedIn: true }, () => {
      if (this.onLoggedIn) {
        this.onLoggedIn(user, isAdmin);
        this.onLoggedIn = null;
      }
    });
  };

  register = async (email, password, body) => {
    await this.auth.register(email, password, body);
    await this.updateProfile();
    const { user, isAdmin } = this.state;

    this.setState({ loggedIn: true }, () => {
      if (this.onLoggedIn) {
        this.onLoggedIn(user, isAdmin);
        this.onLoggedIn = null;
      }
    });
  };

  forgotPassword = (email) => this.auth.forgotPassword(email);

  resetPassword = (token, password) => this.auth.resetPassword(token, password);

  validateToken = (token) => this.auth.validateToken(token);

  promptLogin = (onLoggedIn) => {
    this.onLoggedIn = onLoggedIn;
  };

  render() {
    const { children } = this.props;
    const { loggedIn, user, favoritedListings, isAdmin, isInitialized } = this.state;

    return (
      <UserContext.Provider
        value={{
          claimListing: this.claimListing,
          login: this.login,
          promptLogin: this.promptLogin,
          updateProfile: this.updateProfile,
          forgotPassword: this.forgotPassword,
          register: this.register,
          resetPassword: this.resetPassword,
          validateToken: this.validateToken,
          loggedIn,
          user,
          favoritedListings,
          isAdmin,
          isInitialized,
          addListingToFavorites: this.addListingToFavorites,
          removeListingFromFavorites: this.removeListingFromFavorites,
          toggleListingFavorite: this.toggleListingFavorite,
          isListingFavorited: this.isListingFavorited,
          logoff: this.logoff,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export default UserContextProvider;
