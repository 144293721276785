import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import { string as yupString, object as yupObject } from 'yup';
import { Formik, Form } from 'formik';
import fetch from 'node-fetch';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useRexUser } from '@rex-change/brix';
import rexStorage from '@rex-change/rexlib/dist/rexStorage';
import { REX_SVC_CLIENT_KEYCLOAK_HOST } from 'constants/api';
import { KEYCLOAK_ACCESS_TOKEN, KEYCLOAK_REFRESH_TOKEN } from 'constants/cookies';
import logger from 'utils/logger';
import FormikTextField from '../HumanlessListingFlow/Inputs/FormikTextField';

const useStyles = makeStyles((theme) => ({
  field: {
    margin: theme.spacing(1, 0),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

function LoginForm({
  onSuccess,
  toRegister,
  toForgotPassword,
  initialEmailAddress,
  descriptionCopy,
  ctaCopy,
  includeHeaders,
}) {
  const id = uuid();
  const classes = useStyles();
  const history = useHistory();
  const { login } = useRexUser();

  let myHeaders;
  if (includeHeaders) {
    myHeaders = new fetch.Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  const urlencoded = new URLSearchParams();
  urlencoded.append('client_id', 'site-static');
  urlencoded.append('grant_type', 'password');

  const forgotPasswordClick =
    toForgotPassword ||
    (() => {
      const { location } = history;
      history.push({
        ...location,
        hash: '#forgot-password',
      });
    });

  const initialValues = {
    ...LoginForm.initialValues,
    email: initialEmailAddress ?? LoginForm.initialValues.email,
  };

  const onRegisterClick =
    toRegister ||
    (() => {
      const { location } = history;
      history.push({
        ...location,
        hash: '#register',
      });
    });

  const onSubmit = ({ email, password }, { setSubmitting, setErrors }) => {
    urlencoded.append('username', email);
    urlencoded.append('password', password);

    const requestOptions = {
      method: 'POST',
      body: urlencoded,
      redirect: 'follow',
    };

    if (includeHeaders) {
      requestOptions.headers = myHeaders;
    }

    fetch(
      `${REX_SVC_CLIENT_KEYCLOAK_HOST}/realms/rexcustomers/protocol/openid-connect/token`,
      requestOptions,
    )
      .then((response) => response.text())
      .then((result) => {
        const resultObject = JSON.parse(result);
        const { access_token: accessToken, refresh_token: refreshToken } = resultObject;
        if (!!accessToken && !!refreshToken) {
          rexStorage.setCookie(KEYCLOAK_ACCESS_TOKEN, accessToken);
          rexStorage.setCookie(KEYCLOAK_REFRESH_TOKEN, refreshToken);
        } else {
          throw new Error(
            `Missing keycloak ${!accessToken && 'access token, '} ${
              !refreshToken && 'refresh token'
            }`,
          );
        }
      })
      .catch((error) => {
        logger.error(error);
        rexStorage.remove(KEYCLOAK_ACCESS_TOKEN);
        rexStorage.remove(KEYCLOAK_REFRESH_TOKEN);
      })
      .finally(async () => {
        try {
          await login(email, password);
          onSuccess();
        } catch (err) {
          setErrors({
            form: err.message,
          });
        }
        setSubmitting(false);
      });
  };

  return (
    <Formik
      validationSchema={LoginForm.formSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form className={classes.wrapper}>
          <Typography data-test-id="rex-sign-in-form-desctiption">{descriptionCopy}</Typography>
          <FormikTextField
            id={`${id}-email`}
            type="email"
            name="email"
            label="Email"
            className={classes.field}
            fullWidth
            variant="outlined"
            data-rex-id="login-email-field"
            data-test-id="login-email-field"
          />
          <FormikTextField
            id={`${id}-password`}
            type="password"
            name="password"
            label="Password"
            className={classes.field}
            fullWidth
            variant="outlined"
            data-rex-id="login-password-field"
            data-test-id="login-password-field"
          />
          <Button
            type="submit"
            disabled={isSubmitting}
            className={classes.button}
            color="primary"
            variant="contained"
            data-test-id="signin"
            data-rex-id="login-signin-btn"
          >
            {ctaCopy}
          </Button>
          <Button
            type="button"
            onClick={forgotPasswordClick}
            disabled={isSubmitting}
            className={classes.button}
            data-test-id="rex-sign-in-form-forgot-password-button"
          >
            Forgot My Password
          </Button>
          {errors.form && (
            <Alert severity="error" data-test-id="login-error">
              There was an issue with your login credentials. Please try again, or{' '}
              <Button type="button" onClick={onRegisterClick} disabled={isSubmitting}>
                Reset your password
              </Button>
            </Alert>
          )}
          <Typography data-test-id="rex-sign-in-form-footer-question">
            Don't have an account?{' '}
            <Button
              type="button"
              onClick={onRegisterClick}
              disabled={isSubmitting}
              data-test-id="rex-signin-form-signup-button"
            >
              Sign up here!
            </Button>
          </Typography>
        </Form>
      )}
    </Formik>
  );
}

LoginForm.propTypes = {
  onSuccess: PropTypes.func,
  toRegister: PropTypes.func,
  toForgotPassword: PropTypes.func,
  initialEmailAddress: PropTypes.string,
  descriptionCopy: PropTypes.string,
  ctaCopy: PropTypes.string,
  includeHeaders: PropTypes.bool,
};

LoginForm.defaultProps = {
  onSuccess: () => {},
  toRegister: null,
  toForgotPassword: null,
  initialEmailAddress: null,
  descriptionCopy: 'If you have an account with us, sign in using your email address.',
  ctaCopy: 'Sign In',
  includeHeaders: true,
};

LoginForm.formSchema = yupObject().shape({
  email: yupString()
    .email('A valid email address is required.')
    .required('An email address is required.'),
  password: yupString().required('Please enter your password'),
});

LoginForm.initialValues = {
  email: '',
  password: '',
};

export default LoginForm;
