export const PHONE_NUMBER = '8553424739';
export const EMAIL_ADDRESS = 'hello@rexhomes.com';
export const PRESS_EMAIL = 'press@rexhomes.com';
export const SUPPORT_EMAIL = 'support@rexhomes.com';
export const CAREERS_EMAIL = 'careers@rexhomes.com';
export const INSURANCE_EMAIL = 'info@rexsurance.com';
export const INSURANCE_PHONE = '8557397727';
export const SHERPA_PHONE_NUMBER = '8559364064';
export const RHL_PHONE_NUMBER = '8559553095';
export const ZERO_PHONE_NUMBER = '8557390607';
export const RADIO_ZERO_PHONE_NUMBER = '8557390550';
