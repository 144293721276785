import logger from 'utils/logger';
import RexAPI from '@rex-change/rexlib/dist/RexAPI';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import component from './AboutPage.loadable';

export const isBoardMember = ({ role } = {}) => {
  return role.includes('Board');
};

const client = new RexAPI({
  baseURL: REX_SVC_REX_APP_HOST,
});

const fetchData = async () => {
  let employees = [];
  let boardMembers = [];
  let fullList;

  try {
    const res = await client.get('/api/employee');
    fullList = res.data;
    fullList = fullList.filter((person) => !!(person.image && person.image.publicUrl));

    employees = fullList.filter((employee) => !isBoardMember(employee));
    boardMembers = fullList.filter(isBoardMember);
  } catch (err) {
    logger.error(err.message);
    logger.error(err.stack);
  }

  return {
    employees,
    boardMembers,
  };
};

export default {
  path: '/about',
  exact: true,
  component,
  fetchData,
};
