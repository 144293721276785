import { REX_SVC_REX_APP_HOST } from 'constants/api';
import logger from 'utils/logger';
import VendorApi from './VendorApi';

export const cleanParams = (params) => {
  const cleaned = { ...params };

  Object.entries(cleaned).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      cleaned[key] = JSON.stringify(value);
    }
  });

  return cleaned;
};

class RexAnalyticsMetrics extends VendorApi {
  constructor() {
    super('REX Analytics', 'rex-analyics', {});
  }

  _pageView() {
    return new Promise((resolve) => {
      this.lib.pageView();
      resolve({});
    });
  }

  _user(userId) {
    return new Promise((resolve) => {
      this.userId = userId;
      resolve({
        userId,
      });
    });
  }

  _track(eventName, params) {
    const { value, ...whitelistedParams } = params;
    const cleanedParams = cleanParams(whitelistedParams);

    return new Promise((resolve) => {
      this.lib.trackEvent(eventName, cleanedParams);
      resolve({
        eventName,
        params: cleanedParams,
      });
    });
  }

  _init() {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line global-require
      import(/* webpackChunkName: "rex-analytics" */ '@rex-change/rexlib/dist/RexAnalytics')
        .then(({ RexAnalytics }) => {
          const analytics = new RexAnalytics({
            ...this.options,
            API: REX_SVC_REX_APP_HOST,
          });
          analytics.setLogLevel(0);
          analytics.startFlushEvents();
          resolve(analytics);
        })
        .catch((err) => {
          logger.error('Could not load Rex Analytics Library', err);
          reject();
        });
    });
  }
}

export default RexAnalyticsMetrics;
