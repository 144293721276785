import React from 'react';
import PropTypes from 'prop-types';
import { PropTypes as MetricsPropTypes } from 'react-metrics';
import { v4 as uuid } from 'uuid';
import { Link, useHistory } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { string as yupString, object as yupObject } from 'yup';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { PASSWORD_REGEX } from 'constants/passwordPolicy';
import FormikTextField from 'components/HumanlessListingFlow/Inputs/FormikTextField';
import { useRexUser } from '@rex-change/brix';
import logger from 'utils/logger';

export const EMAIL_EXISTS = 'EmailExistsException';

const useStyles = makeStyles((theme) => ({
  field: {
    margin: theme.spacing(1, 0),
  },
  button: {
    margin: theme.spacing(1, 0),
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

function RegisterForm(
  { onSuccess, toSignIn, initialEmailAddress, descriptionCopy, ctaCopy },
  { metrics },
) {
  const { register } = useRexUser();
  const id = uuid();
  const classes = useStyles();
  const history = useHistory();

  const signInClick =
    toSignIn ||
    (() => {
      const { location } = history;
      history.push({
        ...location,
        hash: '#login',
      });
    });

  const onFormSubmit = async (
    { email, password, firstName, lastName },
    { setSubmitting, setErrors },
  ) => {
    metrics.track('click', { category: 'register-form', label: 'form-submit' });

    const body = {
      firstName,
      lastName,
      marketingAlertsEnabled: false,
      registrationIntent: 'DEFAULT',
    };

    try {
      await register(email, password, body);

      metrics.track('submit', { category: 'register-form', label: 'success' });

      onSuccess();
    } catch (error) {
      const response = error?.response?.data;
      const message = response ? response.title : error?.message ?? error;
      const formErrors = {};

      logger.error('Error submitting register form: ', message);
      metrics.track('submit', { category: 'register-form', label: 'error' });

      if (response?.detail === EMAIL_EXISTS) {
        formErrors.email = response?.title;
      } else {
        formErrors.form = response?.title ?? message;
      }

      setErrors(formErrors);
    }

    setSubmitting(false);
  };

  const initialValues = {
    ...RegisterForm.initialValues,
    email: initialEmailAddress ?? RegisterForm.initialValues.email,
  };

  return (
    <Formik
      validationSchema={RegisterForm.formSchema}
      initialValues={initialValues}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, errors }) => (
        <Form className={classes.wrapper}>
          <Typography>{descriptionCopy}</Typography>
          <FormikTextField
            id={`${id}-first-name`}
            name="firstName"
            label="First Name"
            required
            className={classes.field}
            fullWidth
            variant="outlined"
            data-rex-id="register-form-first-name"
          />
          <FormikTextField
            id={`${id}-last-name`}
            name="lastName"
            label="Last Name"
            required
            className={classes.field}
            fullWidth
            variant="outlined"
            data-rex-id="register-form-last-name"
          />
          <FormikTextField
            id={`${id}-email`}
            name="email"
            type="email"
            label="Email"
            required
            className={classes.field}
            fullWidth
            variant="outlined"
            data-rex-id="register-form-email"
          />
          <FormikTextField
            id={`${id}-password`}
            name="password"
            type="password"
            label="Password"
            required
            className={classes.field}
            fullWidth
            variant="outlined"
            data-rex-id="register-form-password"
          />
          <Typography>
            By clicking "Create Account" you are agreeing to the REX{' '}
            <Link to="/terms-and-privacy/terms" rel="noopener noreferrer" target="_blank">
              Terms of Use
            </Link>
            {' and '}
            <Link to="/terms-and-privacy/privacy" rel="noopener noreferrer" target="_blank">
              Privacy Policy
            </Link>
          </Typography>
          <Button
            type="submit"
            disabled={isSubmitting}
            className={classes.button}
            color="primary"
            variant="contained"
            data-rex-id="register-form-submit"
          >
            {ctaCopy}
          </Button>
          {errors.form && <Alert severity="error">{errors.form}</Alert>}
          <Typography>
            Have an account?
            <Button type="button" onClick={signInClick} disabled={isSubmitting}>
              Sign In
            </Button>
          </Typography>
        </Form>
      )}
    </Formik>
  );
}

RegisterForm.propTypes = {
  onSuccess: PropTypes.func,
  toSignIn: PropTypes.func,
  initialEmailAddress: PropTypes.string,
  descriptionCopy: PropTypes.string,
  ctaCopy: PropTypes.string,
};

RegisterForm.defaultProps = {
  onSuccess: () => {},
  toSignIn: null,
  initialEmailAddress: null,
  descriptionCopy: 'Enter your details to create a REX account.',
  ctaCopy: 'Create Account',
};

RegisterForm.formSchema = yupObject().shape({
  firstName: yupString().required('A first name is required.'),
  lastName: yupString().required('A last name is required.'),
  email: yupString()
    .email('A valid email address is required')
    .required('An email address is required.'),
  password: yupString()
    .required('You must enter a valid password.')
    .matches(PASSWORD_REGEX, 'Your password must be 6 - 30 Upper and Lower Case Letters & Numbers'),
});

RegisterForm.initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
};

RegisterForm.contextTypes = {
  metrics: MetricsPropTypes.metrics,
};

export default RegisterForm;
