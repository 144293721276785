import { fetchData as listingsFetchData } from 'containers/ListingSearchPage/ListingSearchPage.route';
import LoadableCityListingSearchPage from './CityListingSearchPage.loadable';

function formatParam(param) {
  if (param?.split) {
    const words = param.split('-');
    const validWords = words.filter((word) => !!word);
    const formattedWords = validWords.map((word) => `${word[0].toUpperCase()}${word.slice(1)}`);
    return formattedWords.join(' ');
  }

  return '';
}

async function fetchData({ city, state }) {
  const listingsData = await listingsFetchData();

  const formattedCity = formatParam(city);
  const formattedState = formatParam(state);

  return { ...listingsData, city, formattedCity, state, formattedState };
}

export default {
  path: '/homes/:state/:city',
  exact: true,
  component: LoadableCityListingSearchPage,
  fetchData: ({ match: { params } }) => fetchData(params),
};
