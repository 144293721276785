import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const TermsAndPrivacyPageLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: "terms-and-privacy" */ 'containers/TermsAndPrivacyPage'),
  loading: Loading,
});

export default TermsAndPrivacyPageLoadable;
