import RexAnalyticsMetrics from './RexAnalyticsMetrics';
import GoogleAnalytics from './GoogleAnalytics';
import FacebookPixel from './FacebookPixel';
import CriteoOneTag from './CriteoOneTag';
import TheTradeDeskTag from './TheTradeDeskTag';
import pkg from '../../../package.json';

const config = {
  vendors: [
    {
      api: new GoogleAnalytics(),
    },
    {
      api: new FacebookPixel(),
    },
    {
      api: new RexAnalyticsMetrics(),
    },
    {
      api: new CriteoOneTag(),
    },
    {
      api: new TheTradeDeskTag(),
    },
  ],
  pageDefaults: ({ pathname }) => {
    const path = pathname || window.location.pathname;
    const paths = path.split('/');
    const timestamp = new Date();

    return {
      timestamp,
      build: pkg.version,
      siteName: 'rexhomes.com',
      category: paths?.[1] ?? 'landing',
      path,
      title: document.title,
    };
  },
};

export const options = {
  useTrackBinding: false,
};

export default config;
