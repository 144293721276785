export const parseRedirect = ({ search }) => {
  const queryString = new URLSearchParams(search);
  return queryString.get('redirect');
};
export const redirect = (url, history) => history.push(url);

export default {
  parseRedirect,
  redirect,
};
