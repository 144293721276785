import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import sortedIndexBy from 'lodash/sortedIndexBy';
import UIManagerError, { ERROR_NAME_ID } from './UIManagerError';

export const store = createContext({});

export const { Provider, Consumer } = store;

export const initialState = {
  queue: [],
};

export const REGISTER = 'REGISTER';
export const UNREGISTER = 'UNREGISTER';
export const CLEAR_QUEUE = 'CLEAR_QUEUE';

export const actions = {
  REGISTER,
  UNREGISTER,
  CLEAR_QUEUE,
};

export const register = (state = initialState, el = {}) => {
  const { queue } = state;

  const element = {
    priority: 0,
    isAvailable: true,
    ...el,
  };

  if (element.id === null || element.id === undefined) {
    const error = new UIManagerError('Missing Id', ERROR_NAME_ID);
    throw error;
  }

  const isDuplicate = !!queue.find((elem) => elem.id === element.id);

  if (isDuplicate) {
    const error = new UIManagerError(`Duplicate Id: ${element.id}`, ERROR_NAME_ID);
    throw error;
  }

  const sortIndex = sortedIndexBy(queue, element, ({ priority }) => 0 - priority);

  const newQueue = [...queue];

  newQueue.splice(sortIndex, 0, element);

  const newState = { ...state, queue: newQueue };

  return newState;
};

export const unregister = (state = initialState, id) => {
  const newQueue = state.queue.filter((elem) => elem.id !== id);

  return { ...state, queue: newQueue };
};

export const clearQueue = (state = initialState) => {
  const newState = { ...state, queue: [] };

  return newState;
};

export const reducer = (state = initialState, { type, data } = {}) => {
  switch (type) {
    case REGISTER:
      return register(state, data);
    case UNREGISTER:
      return unregister(state, data);
    case CLEAR_QUEUE:
      return clearQueue(state);
    default:
      return { ...state };
  }
};

export function UIManagerProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={[state, dispatch]}>{children}</Provider>;
}

UIManagerProvider.propTypes = {
  children: PropTypes.node,
};

UIManagerProvider.defaultProps = {
  children: null,
};

export default store;
