import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const LoadablePaymentCalculator = Loadable({
  loader: () =>
    import(/* webpackChunkName: "payment-calculator" */ 'containers/PaymentCalculatorPage'),
  loading: Loading,
});

export default LoadablePaymentCalculator;
