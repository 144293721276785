import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export default Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "sign-up-friend-investments" */ 'containers/SignUpFriendInvestmentsPage'
    ),
  loading: Loading,
});
