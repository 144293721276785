export const PREFERRED_SEARCH_REGION_COOKIE = 'RexApp_PreferredSearchRegionCode';
export const BUYER_LEAD_POPUP_COOKIE = 'rex-buyer-lead-popup';
export const POPUP_COOKIE = 'rex-popup';
export const REX_NO_TRACK_COOKIE = '__rx_notrack';
export const VIRTUAL_TOUR_OVERLAY_COOKIE = 'rex-virtual-tour-overlay';
export const REX_BUYER_LEAD_POPOVER_COOKIE = 'rex-buyer-lead-popover';
export const REX_USER_TOKEN = 'RX_TOKEN_STORAGE_ID';
export const REX_ADMIN_LOGIN = 'rex-admin-login';
export const KEYCLOAK_ACCESS_TOKEN = 'RX_KEYCLOAK_ACCESS_STORAGE_ID';
export const KEYCLOAK_REFRESH_TOKEN = 'RX_KEYCLOAK_REFRESH_STORAGE_ID';
export const LISTING_SEARCH_SIGN_UP_COOKIE = 'rex-sign-up-banner';

export default {
  POPUP_COOKIE,
  PREFERRED_SEARCH_REGION_COOKIE,
  BUYER_LEAD_POPUP_COOKIE,
  REX_NO_TRACK_COOKIE,
  VIRTUAL_TOUR_OVERLAY_COOKIE,
  KEYCLOAK_ACCESS_TOKEN,
  KEYCLOAK_REFRESH_TOKEN,
  LISTING_SEARCH_SIGN_UP_COOKIE,
};
