import logger from 'utils/logger';
import GeoClient from '@rex-change/rexlib/dist/GeoClient';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import LoadableBuyWithRex from './BuyWithRexPage.loadable';

const geoClient = new GeoClient({
  baseURL: REX_SVC_REX_APP_HOST,
});

const fetchData = async () => {
  let regions = [];
  let searchStates = [];

  try {
    regions = await geoClient.getRegions();
    regions.sort((a, b) => b.available - a.available);
  } catch (err) {
    logger.warn(err.message || err);
  }

  try {
    searchStates = await geoClient.getStates();
  } catch (err) {
    logger.warn(err.message || err);
    searchStates = [];
  }

  return {
    regions,
    searchStates,
  };
};

export default {
  path: '/buy-with-rex',
  exact: true,
  component: LoadableBuyWithRex,
  fetchData,
};
