import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  resetPasswordModal: {
    textAlign: 'center',
  },
}));

export function TokenErrorModal(props) {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);

  const { history, location, hash } = props;
  const currHash = location && location.hash;

  const closeDialog = useCallback(() => {
    const loc = { ...location, hash: '', search: '' };
    history.replace(loc);
  }, [history, location]);

  useEffect(() => {
    setOpen(currHash === hash);
  }, [currHash, hash]);

  return (
    <Modal
      contentLabel="Oops"
      open={isOpen}
      onClose={closeDialog}
      className={classes.resetPasswordModal}
    >
      <div>
        <Typography variant="h6">
          Looks like your token has either expired or is invalid.
        </Typography>
        <Typography variant="h6">Please try again later.</Typography>
      </div>
    </Modal>
  );
}

TokenErrorModal.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  hash: PropTypes.string.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
};

export default withRouter(TokenErrorModal);
