import GeoClient from '@rex-change/rexlib/dist/GeoClient';
import CMSClient from '@rex-change/rexlib/dist/CMSClient';
import logger from 'utils/logger';
import { REX_SVC_REX_APP_HOST, REX_SVC_CMS_HOST } from 'constants/api';
import LoadableBuyerRebate from './BuyerRebatePage.loadable';

const geoClient = new GeoClient({
  baseURL: REX_SVC_REX_APP_HOST,
});

const cmsClient = new CMSClient({
  baseURL: REX_SVC_CMS_HOST,
});

async function fetchData() {
  let searchStates;
  let totalSavings;

  try {
    searchStates = await geoClient.getStates();
  } catch (err) {
    logger.warn(err.message || err);
    searchStates = [];
  }

  try {
    totalSavings = await cmsClient.getKVP('rex-buyer-rebate');
  } catch (err) {
    logger.error(err.message);
    logger.error(err.stack);
    totalSavings = 0;
  }

  return {
    searchStates,
    totalSavings,
  };
}

export default {
  path: '/service/buyerrebate',
  exact: true,
  component: LoadableBuyerRebate,
  fetchData,
};
