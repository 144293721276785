import rexCookies from '@rex-change/rexlib/dist/rexCookies';

export function getUtmParams(isSessionOnly = false) {
  const sourceCookieName = `REX_source${isSessionOnly ? '__session' : ''}`;
  const mediumCookieName = `REX_medium${isSessionOnly ? '__session' : ''}`;
  const campaignCookieName = `REX_campaign${isSessionOnly ? '__session' : ''}`;

  let source = rexCookies.isSet(sourceCookieName) && rexCookies.get(sourceCookieName);
  let medium = rexCookies.isSet(mediumCookieName) && rexCookies.get(mediumCookieName);
  let campaign = rexCookies.isSet(campaignCookieName) && rexCookies.get(campaignCookieName);

  if (!source || !medium || !campaign) {
    const queryParams = new URLSearchParams(window?.location?.search);
    source = queryParams.get('utm_source');
    medium = queryParams.get('utm_medium');
    campaign = queryParams.get('utm_campaign');
  }

  return {
    source,
    medium,
    campaign,
  };
}

export function setUtmParams({ source, medium, campaign }, isSessionOnly = false) {
  const sourceCookieName = `REX_source${isSessionOnly ? '__session' : ''}`;
  const mediumCookieName = `REX_medium${isSessionOnly ? '__session' : ''}`;
  const campaignCookieName = `REX_campaign${isSessionOnly ? '__session' : ''}`;
  const options = isSessionOnly ? { expireDays: 0 } : {};

  rexCookies.set(sourceCookieName, source, options);
  rexCookies.set(mediumCookieName, medium, options);
  rexCookies.set(campaignCookieName, campaign, options);
}

export function populateUtmParams() {
  const sessionParams = getUtmParams(true);
  const firstTouchParams = getUtmParams(false);

  setUtmParams(sessionParams, true);
  setUtmParams(firstTouchParams, false);
}
