import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { PHONE_NUMBER } from 'constants/contact';
import formatPhone from '@rex-change/rexlib/dist/formatPhone';
import NewAreaSignupForm from 'components/NewAreaSignupForm';
import Modal from 'components/Modal';
import { asNameString } from 'utils/supportedStatesCopy';
import { GlobalDataContext } from 'contexts/GlobalDataContext/GlobalDataContext';
import './NewAreaSignupModal.scss';

export class NewAreaSignupModal extends Component {
  static propTypes = {
    location: PropTypes.shape({
      hash: PropTypes.string,
    }).isRequired,
    hash: PropTypes.string.isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func,
      replace: PropTypes.func,
    }).isRequired,
    phoneNumber: PropTypes.string,
  };

  static defaultProps = {
    phoneNumber: PHONE_NUMBER,
  };

  state = {
    openModal: false,
    showForm: true,
    showSuccess: false,
    title: "We're Expanding Quickly and May Be Able to Serve You",
  };

  onSuccess = () => {
    this.setState({
      showForm: false,
      showSuccess: true,
      title: 'Thanks For The Info!',
    });
  };

  static getDerivedStateFromProps(props, state) {
    const nextState = {
      ...state,
    };

    const { location, hash } = props;
    const currHash = location && location.hash;

    nextState.openModal = currHash === hash;

    return nextState;
  }

  closeDialog = () => {
    const { history, location } = this.props;

    const loc = { ...location, hash: '' };

    history.replace(loc);

    this.afterClose();
  };

  afterClose = () => {
    this.resetDialog();
  };

  resetDialog() {
    this.setState({
      openModal: false,
      showForm: true,
      showSuccess: false,
      title: "We're Expanding Quickly and May Be Able to Serve You",
    });
  }

  render() {
    const { phoneNumber } = this.props;

    const { openModal, showForm, showSuccess, title } = this.state;

    return (
      <Modal
        contentLabel={title}
        open={openModal}
        onClose={this.closeDialog}
        className="rex-new-area-signup-modal"
      >
        {showForm && (
          <div key="form" className="rex-new-area-signup-modal__step">
            <p className="rex-new-area-signup-modal__description">
              We're sorry your address didn't come up immediately. We would love to connect with
              you! We currently support home sales across {asNameString()}. Enter your info to find
              out your options or call us to discuss at:
              <a className="rex-new-area-signup-modal__phone" href={`tel:${phoneNumber}`}>
                {formatPhone(phoneNumber)}
              </a>
            </p>
            <NewAreaSignupForm onSuccess={this.onSuccess} />
          </div>
        )}
        {showSuccess && (
          <div key="success" className="rex-new-area-signup-modal__step">
            <p className="rex-new-area-signup-modal__description">
              We will get in touch with you shortly to let you know if we can service your area. If
              you have any questions, you can give us a call at:
              <a className="rex-new-area-signup-modal__phone" href={`tel:${phoneNumber}`}>
                {formatPhone(phoneNumber)}
              </a>
            </p>
            <button
              type="button"
              onClick={this.closeDialog}
              className="rex-new-area-signup-modal__close-btn"
            >
              Close
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

const NewAreaSignupModalRouter = withRouter(NewAreaSignupModal);

const WithGlobalData = (props) => (
  <GlobalDataContext.Consumer>
    {({ phoneNumber }) => (
      // Disabled as this is a pass-through for non-context props
      // eslint-disable-next-line react/jsx-props-no-spreading
      <NewAreaSignupModalRouter {...props} phoneNumber={phoneNumber} />
    )}
  </GlobalDataContext.Consumer>
);

export default WithGlobalData;
