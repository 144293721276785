import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const LoadableFrequentlyAskedQuestions = Loadable({
  loader: () =>
    import(/* webpackChunkName: "faq-page" */ 'containers/FrequentlyAskedQuestionsPage'),
  loading: Loading,
});

export default LoadableFrequentlyAskedQuestions;
