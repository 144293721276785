import rexCookies from '@rex-change/rexlib/dist/rexCookies';

const REFERRER_COOKIE_NAME = 'REX_referrer';
const LANDING_PAGE_COOKIE_NAME = 'REX_landing_page';

export function getSessionAttributes() {
  const referrer =
    (rexCookies.isSet(REFERRER_COOKIE_NAME) && rexCookies.get(REFERRER_COOKIE_NAME)) ||
    document.referrer;
  const landingPage =
    (rexCookies.isSet(LANDING_PAGE_COOKIE_NAME) && rexCookies.get(LANDING_PAGE_COOKIE_NAME)) ||
    window.location.href;

  return {
    referrer,
    landingPage,
  };
}

export function setSessionAttributes({ referrer, landingPage }) {
  const options = { expireDays: 0 };

  rexCookies.set(REFERRER_COOKIE_NAME, referrer, options);
  rexCookies.set(LANDING_PAGE_COOKIE_NAME, landingPage, options);
}

export function populateSessionAttributes() {
  const sessionAttributes = getSessionAttributes();
  setSessionAttributes(sessionAttributes);
}

/*
 * This is a terrible pattern and is only here out of necessity.
 * Replace it as soon as is feasible
 */
export function appendSessionAttributesToUrl(url, appendLandingPageUrl = '') {
  const fullUrl = typeof url === 'string' ? url : '';

  const [rootUrl, queryString] = fullUrl.split('?');
  const { referrer: rexReferrer, landingPage: rexLandingPage } = getSessionAttributes();

  const queryParams = new URLSearchParams(queryString);
  queryParams.set('rexReferrer', rexReferrer);
  queryParams.set('rexLandingPage', rexLandingPage + appendLandingPageUrl);

  return `${rootUrl}?${queryParams}`;
}
