import React from 'react';
import PropTypes from 'prop-types';
import './Loading.scss';

function Loading(props) {
  const { isLoading, timedOut, pastDelay, error } = props;
  if (isLoading) {
    if (timedOut) {
      return (
        <div className="rex-loading rex-loading--timed-out">
          We're sorry, it took to long to load that. Please try again soon.
        </div>
      );
    }
    if (pastDelay) {
      return <div className="rex-loading rex-loading--loading" />;
    }
  }

  if (error) {
    return (
      <div className="rex-loading rex-loading--error">
        We're sorry, there was a problem loading that. Please try again soon.
      </div>
    );
  }

  return null;
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
  timedOut: PropTypes.bool,
  pastDelay: PropTypes.bool,
  error: PropTypes.shape({}),
};

Loading.defaultProps = {
  isLoading: null,
  timedOut: null,
  pastDelay: null,
  error: null,
};

export default Loading;
