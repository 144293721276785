import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import App from 'App';
import { defaultTheme } from '@rex-change/brix';
import ENV from 'constants/env';
import { REX_SVC_CLIENT_MLS_LISTINGS_HOST } from 'constants/api';
import { datadogRum } from '@datadog/browser-rum';
import { CookiesProvider } from 'react-cookie';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import rexSession from '@rex-change/rexlib/dist/rexSession';
import refreshKeycloakTokens from '@rex-change/rexlib/dist/refreshKeycloakTokens';

const httpLink = createHttpLink({
  uri: REX_SVC_CLIENT_MLS_LISTINGS_HOST,
});

export const HEADER_PLATFORM = 'x-rex-platform';
export const HEADER_VISITOR = 'x-rex-visitor';
export const HEADER_SESSION = 'x-rex-visitor-session';
export const HEADER_TOKEN = 'x-rex-token';
export const HEADER_ACCESS_TOKEN = 'x-rex-access-token';

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      [HEADER_PLATFORM]: 'REX_WEB',
      [HEADER_VISITOR]: rexSession.getVisitorGuid(),
      [HEADER_SESSION]: rexSession.getSessionGuid(),
      [HEADER_TOKEN]: rexSession.getToken(),
      [HEADER_ACCESS_TOKEN]: rexSession.getKeycloakAccessToken(),
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache().restore(window.apolloState),
});

if (ENV.DD_RUM_ENABLED) {
  datadogRum.init({
    applicationId: ENV.DD_RUM_APP_ID,
    clientToken: ENV.DD_RUM_CLIENT_TOKEN,
    datacenter: 'us',
    sampleRate: 5,
  });
}

function ClientApp() {
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  useEffect(() => {
    refreshKeycloakTokens(
      `${ENV.REX_SVC_CLIENT_KEYCLOAK_HOST}/realms/rexcustomers/protocol/openid-connect/token`,
      'site-static',
    );
  }, []);

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </BrowserRouter>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

window.onload = () =>
  Loadable.preloadReady().then(() => {
    ReactDOM.hydrate(<ClientApp />, document.getElementById('root'));
  });
