import logger from 'utils/logger';
import CMSClient from '@rex-change/rexlib/dist/CMSClient';
import { REX_SVC_CMS_HOST } from 'constants/api';
import LoadableFrequentlyAskedQuestions from './FrequentlyAskedQuestionsPage.loadable';

const cmsClient = new CMSClient({
  baseURL: REX_SVC_CMS_HOST,
});

async function fetchData() {
  let fieldmeta = {};

  try {
    fieldmeta = await cmsClient.getKVP('rex-frequently-asked-questions');
  } catch (err) {
    logger.error(err.message);
    logger.error(err.stack);
    fieldmeta = {};
  }

  return { ...fieldmeta };
}

export default {
  path: '/faq/:section?',
  exact: true,
  component: LoadableFrequentlyAskedQuestions,
  fetchData,
};
