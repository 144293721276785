import ListingDisplayPage from 'containers/ListingDisplayPage/ListingDisplayPage.route';
import LandingPage from 'containers/LandingPage/LandingPage.route';
import CompareLandingPage from 'containers/CompareLandingPage/CompareLandingPage.route';
import TermsAndPrivacy from 'containers/TermsAndPrivacyPage/TermsAndPrivacyPage.route';
import SellWithRexPage from 'containers/SellWithRexPage/SellWithRexPage.route';
import SellWithRexMarketingLanderPage from 'containers/SellWithRexMarketingLanderPage/SellWithRexMarketingLanderPage.route';
import BuyerRebatePage from 'containers/BuyerRebatePage/BuyerRebatePage.route';
import CareersPage from 'containers/CareersPage/CareersPage.route';
import ContactPage from 'containers/ContactPage/ContactPage.route';
import FrequentlyAskedQuestionsPage from 'containers/FrequentlyAskedQuestionsPage/FrequentlyAskedQuestionsPage.route';
import BuyWithRexPage from 'containers/BuyWithRexPage/BuyWithRexPage.route';
import SocialMissionPage from 'containers/SocialMissionPage/SocialMissionPage.route';
import AboutPage from 'containers/AboutPage/AboutPage.route';
import TestimonialsPage from 'containers/TestimonialsPage/TestimonialsPage.route';
import InsurancePage from 'containers/InsurancePage/InsurancePage.route';
import InsuranceApplicationPage from 'containers/InsuranceApplicationPage/InsuranceApplicationPage.route';
import SignUpFriendInvestmentsPage from 'containers/SignUpFriendInvestmentsPage/SignUpFriendInvestmentsPage.route';
import PartnersPage from 'containers/PartnersPage/PartnersPage.route';
import CityListingSearchPage from 'containers/CityListingSearchPage/CityListingSearchPage.route';
import ListingSearchPage from 'containers/ListingSearchPage/ListingSearchPage.route';
import PaymentCalculatorPage from 'containers/PaymentCalculatorPage/PaymentCalculatorPage.route';
import HomePage from 'containers/HomePage/HomePage.route';
import RedirectTokenPage from 'containers/RedirectTokenPage/RedirectTokenPage.route';
import AdminAuthPage from 'containers/AdminAuthPage/AdminAuthPage.route';
import EmailVerifySuccessPage from 'containers/EmailVerifySuccessPage/EmailVerifySuccessPage.route';
import EmailChangeSuccessPage from 'containers/EmailChangeSuccessPage/EmailChangeSuccessPage.route';
import ResetPasswordRedirectPage from 'containers/ResetPasswordRedirectPage/ResetPasswordRedirectPage.route';
import ServicesPage from 'containers/ServicesPage/ServicesPage.route';
import CCPAPage from 'containers/CCPAPage/CCPAPage.route';
import SellVideoLanderPage from 'containers/SellVideoLanderPage/SellVideoLanderPage.route';
import AppHelloSignPage from 'containers/AppHelloSignPage/AppHelloSignPage.route';
import HelloSignPage from 'containers/HelloSignPage/HelloSignPage.route';
import SherpaPage from 'containers/SherpaPage/SherpaPage.route';
import SherpaSecondPage from 'containers/SherpaSecondPage/SherpaSecondPage.route';
import SigninPage from 'containers/SigninPage/SigninPage.route';
import ErrorPage from 'containers/ErrorPage/ErrorPage.route';
import Rextionary101Page from 'containers/Rextionary101Page/Rextionary101Page.route';
import HomePrepPage from 'containers/HomePrepPage/HomePrepPage.route';
import BrpPhillyPage from 'containers/BrpPhillyPage/BrpPhillyPage.route';
import AllHomesCashBackPage from 'containers/AllHomesCashBack/AllHomesCashBack.route';
import HomeLoansPage from 'containers/HomeLoansPage/HomeLoansPage.route';
import ComparePage from 'containers/ComparePage/ComparePage.route';
import HomeValuePage from 'containers/HomeValuePage/HomeValuePage.route';
import OnboardingPage from 'containers/OnboardingPage/OnboardingPage.route';
import ZeroPage from 'containers/ZeroPage/ZeroPage.route';
import Zero0Page from 'containers/Zero0Page/Zero0Page.route';
import HotdogPage from 'containers/HotdogPage/HotdogPage.route';
import LegacyPathRedirects from './legacyPathRedirects';

const routes = [
  ...LegacyPathRedirects,
  HomeValuePage,
  ListingDisplayPage,
  LandingPage,
  CompareLandingPage,
  TermsAndPrivacy,
  SellWithRexPage,
  SellWithRexMarketingLanderPage,
  AllHomesCashBackPage,
  HomeLoansPage,
  ComparePage,
  BuyerRebatePage,
  CareersPage,
  ContactPage,
  FrequentlyAskedQuestionsPage,
  BuyWithRexPage,
  SocialMissionPage,
  AboutPage,
  TestimonialsPage,
  InsurancePage,
  InsuranceApplicationPage,
  SignUpFriendInvestmentsPage,
  PartnersPage,
  CityListingSearchPage,
  ListingSearchPage,
  PaymentCalculatorPage,
  HomePage,
  EmailChangeSuccessPage,
  EmailVerifySuccessPage,
  ResetPasswordRedirectPage,
  RedirectTokenPage,
  AdminAuthPage,
  ServicesPage,
  CCPAPage,
  SherpaSecondPage,
  SherpaPage,
  AppHelloSignPage,
  HelloSignPage,
  SellVideoLanderPage,
  SigninPage,
  Rextionary101Page,
  HomePrepPage,
  BrpPhillyPage,
  OnboardingPage,
  ZeroPage,
  Zero0Page,
  HotdogPage,
  ErrorPage,
];

export default routes;
