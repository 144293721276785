import logger from 'utils/logger';
import CMSClient from '@rex-change/rexlib/dist/CMSClient';
import { REX_SVC_CMS_HOST } from 'constants/api';
import SocialMissionPageLoadable from './SocialMissionPage.loadable';

const FIELDMETA_KEY = 'rex-social-mission';

const cmsClient = new CMSClient({
  baseURL: REX_SVC_CMS_HOST,
});

const fetchData = async () => {
  let content = {};

  try {
    content = await cmsClient.getKVP(FIELDMETA_KEY);
  } catch (err) {
    logger.error(err.message);
    logger.error(err.stack);
  }

  return { ...content };
};

export default {
  path: '/rex-social-mission',
  exact: true,
  component: SocialMissionPageLoadable,
  fetchData,
};
