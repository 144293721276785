import ENV from './env';

export const getServerHostVarName = (name) => {
  return name.replace('_CLIENT_', '_SERVER_');
};

export const getHost = (name) => {
  return !__CLIENT__ && ENV[getServerHostVarName(name)]
    ? ENV[getServerHostVarName(name)]
    : ENV[name];
};

export const REX_SVC_SUGGEST_HOST = getHost('REX_SVC_REX_SEARCH_HOST');
export const REX_SVC_REX_APP_HOST = getHost('REX_SVC_CLIENT_REX_APP_HOST');
export const REX_SVC_CMS_HOST = `${getHost('REX_SVC_CLIENT_CMS_HOST')}/api`;
export const REX_SVC_JAZZHR_HOST = `${getHost('REX_SVC_CLIENT_JAZZHR_HOST')}/jazzhr`;
export const REX_SVC_CLIENT_INVESTMENTS_HOST = getHost('REX_SVC_CLIENT_INVESTMENTS_HOST');
export const REX_SVC_CLIENT_PHONE_TRACKING_HOST = getHost('REX_SVC_CLIENT_PHONE_TRACKING_HOST');
export const REX_SVC_CLIENT_SHOWINGS_HOST = getHost('REX_SVC_CLIENT_SHOWINGS_HOST');
export const REX_SVC_LISTING_QUERY_HOST = getHost('REX_SVC_CLIENT_LISTING_QUERY_HOST');
export const REX_SVC_CLIENT_SHERPA_HOST = getHost('REX_SVC_CLIENT_SHERPA_HOST');
export const REX_SVC_CLIENT_DRAFT_OFFERS_HOST = getHost('REX_SVC_CLIENT_DRAFT_OFFERS_HOST');
export const REX_SVC_CLIENT_REX_AIR_HOST = getHost('REX_SVC_CLIENT_REX_AIR_HOST');
export const REX_SVC_SITEMAP_URL = getHost('REX_SVC_SITEMAP_URL');
export const REX_SVC_CLIENT_LEAD_CAPTURE_HOST = getHost('REX_SVC_CLIENT_LEAD_CAPTURE_HOST');
export const REX_SVC_CLIENT_MLS_LISTINGS_HOST = `${getHost(
  'REX_SVC_CLIENT_MLS_LISTINGS_HOST',
)}/graphql`;
export const REX_SVC_CLIENT_BUYER_REBATE_HOST = getHost('REX_SVC_CLIENT_BUYER_REBATE_HOST');
export const REX_SVC_CLIENT_NEW_HOME_ALERTS_HOST = getHost('REX_SVC_CLIENT_NEW_HOME_ALERTS_HOST');
export const REX_SVC_CLIENT_ADDRESS_SUGGEST_HOST = getHost('REX_SVC_CLIENT_ADDRESS_SUGGEST_HOST');
export const REX_SVC_CLIENT_KEYCLOAK_HOST = `${getHost('REX_SVC_CLIENT_KEYCLOAK_HOST')}/auth`;

export default {
  REX_SVC_REX_APP_HOST,
  REX_SVC_SUGGEST_HOST,
  REX_SVC_CMS_HOST,
  REX_SVC_JAZZHR_HOST,
  REX_SVC_CLIENT_INVESTMENTS_HOST,
  REX_SVC_CLIENT_PHONE_TRACKING_HOST,
  REX_SVC_CLIENT_SHOWINGS_HOST,
  REX_SVC_LISTING_QUERY_HOST,
  REX_SVC_CLIENT_SHERPA_HOST,
  REX_SVC_CLIENT_DRAFT_OFFERS_HOST,
  REX_SVC_CLIENT_REX_AIR_HOST,
  REX_SVC_SITEMAP_URL,
  REX_SVC_CLIENT_MLS_LISTINGS_HOST,
  REX_SVC_CLIENT_LEAD_CAPTURE_HOST,
  REX_SVC_CLIENT_NEW_HOME_ALERTS_HOST,
  REX_SVC_CLIENT_ADDRESS_SUGGEST_HOST,
  REX_SVC_CLIENT_KEYCLOAK_HOST,
};
