import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const EmailVerifySuccessPageLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'email-verify-success' */ 'containers/EmailVerifySuccessPage'),
  loading: Loading,
});

export default EmailVerifySuccessPageLoadable;
