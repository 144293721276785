export const LISTING_STATUS_SIGNED = 'SIGNED';
export const LISTING_STATUS_CLAIMED = 'CLAIMED';
export const LISTING_STATUS_COMING_SOON = 'COMING_SOON';
export const LISTING_STATUS_FOR_SALE = 'FOR_SALE';
export const LISTING_STATUS_ACCEPTING_BACKUP_OFFERS = 'ACCEPTING_BACKUP_OFFERS';
export const LISTING_STATUS_IN_ESCROW = 'IN_ESCROW';
export const LISTING_STATUS_SOLD = 'SOLD';
export const LISTING_STATUS_INACTIVE = 'INACTIVE';
export const LISTING_STATUS_PREVIEW = 'PREVIEW';
export const LISTING_STATUS_PENDING = 'PENDING';
export const LISTING_STATUS_NEW = 'NEW';

export const LISTING_SORT_NEWEST = 'publishDate,DESC';
export const LISTING_SORT_PRICE_LOW = 'price,ASC';
export const LISTING_SORT_PRICE_HIGH = 'price,DESC';

export const LISTING_TYPE_REX = 'REX';

export const PROPERTY_TYPE_SINGLE = 'SINGLE';
export const PROPERTY_TYPE_CONDO = 'CONDO';
export const PROPERTY_TYPE_TOWNHOUSE = 'TOWNHOUSE';
export const PROPERTY_TYPE_MULTI_FAMILY = 'MULTI_FAMILY';
export const PROPERTY_TYPE_COOP = 'COOP';
export const PROPERTY_TYPE_LAND = 'LAND';

export const LISTING_PLACEHOLDER_IMG =
  '/assets/images/default-listing-photo.jpg';

export const type = {
  LISTING_TYPE_REX,
};

export const sort = {
  LISTING_SORT_NEWEST,
  LISTING_SORT_PRICE_LOW,
  LISTING_SORT_PRICE_HIGH,
};

export const status = {
  LISTING_STATUS_SIGNED,
  LISTING_STATUS_CLAIMED,
  LISTING_STATUS_COMING_SOON,
  LISTING_STATUS_FOR_SALE,
  LISTING_STATUS_ACCEPTING_BACKUP_OFFERS,
  LISTING_STATUS_IN_ESCROW,
  LISTING_STATUS_SOLD,
  LISTING_STATUS_INACTIVE,
  LISTING_STATUS_PREVIEW,
  LISTING_STATUS_PENDING,
  LISTING_STATUS_NEW,
};

export default {
  LISTING_PLACEHOLDER_IMG,
  status,
  type,
  sort,
};
