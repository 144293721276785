import ENV from 'constants/env';
import VendorApi, { loadScript } from './VendorApi';

class GoogleAnalytics extends VendorApi {
  constructor(
    options = {
      src: `https://www.googletagmanager.com/gtag/js?id=${ENV.REX_GA_TAG}`,
    },
  ) {
    super('google-analytics', 'gtag', options);
  }

  _init() {
    const promise = loadScript(this.options.src).then(() => {
      return window.gtag;
    });

    window.dataLayer = window.dataLayer || [];
    window.gtag =
      window.gtag ||
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      };

    window.gtag('js', new Date());

    window.gtag('config', ENV.REX_GA_TAG, {
      optimize_id: ENV.REX_OPTIMIZE_CONTAINER,
      send_page_view: false,
    });

    return promise;
  }

  _pageView(event, params = {}) {
    const { category, title, location, page } = params;

    const eventParams = {
      page_title: title || category,
      page_location: location || window.location.href,
      page_path: page || window.location.pathname,
    };

    this.lib('event', 'page_view', { ...eventParams });

    return {
      params,
    };
  }

  _track(eventName, params = {}) {
    const { category, action, label, value, nonInteraction } = params;

    const eventParameters = {
      event_action: eventName,
      non_interaction: !!nonInteraction,
    };

    if (category) {
      eventParameters.event_category = category;
    }

    if (action) {
      eventParameters.event_action = action;
    }

    if (label) {
      eventParameters.event_label = label;
    }

    if (value !== undefined && value !== null) {
      eventParameters.value = value;
    }

    this.lib('event', eventName, eventParameters);

    return {
      eventName,
      params,
    };
  }
}

export default GoogleAnalytics;
