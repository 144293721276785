import Loadable from 'react-loadable';
import Loading from 'components/Loading';

const EmailChangeSuccessPageLoadable = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'email-change-success' */ 'containers/EmailChangeSuccessPage'),
  loading: Loading,
});

export default EmailChangeSuccessPageLoadable;
