import ListingClient from '@rex-change/rexlib/dist/ListingClient';
import { REX_SVC_REX_APP_HOST } from 'constants/api';
import logger from 'utils/logger';
import PaymentCalculatorLoadable from './PaymentCalculatorPage.loadable';

const fetchData = async (props = {}, location = {}) => {
  const local = props.location || location;

  let listing = {};
  const listingClient = new ListingClient({
    baseURL: REX_SVC_REX_APP_HOST,
  });

  const { search } = local;

  const queryParams = new URLSearchParams(search);
  const rexURL = queryParams.get('rexURL');

  if (rexURL) {
    try {
      listing = await listingClient.getListingByURL(rexURL);
    } catch (err) {
      logger.error(err.message || err);
      if (err.stack) {
        logger.error(err.stack);
      }
    }
  }

  return {
    listing,
  };
};

export default {
  path: '/draft-offer/payment-calculator',
  component: PaymentCalculatorLoadable,
  fetchData,
};
