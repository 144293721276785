import VendorApi from './VendorApi';

/**
 * Facebook Standard Tracking Events
 * @see https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
 */
const STANDARD_EVENTS = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishList',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'Lead',
  'Purchase',
  'Search',
  'StartTrial',
  'ViewContent',
];

class FacebookPixel extends VendorApi {
  constructor() {
    super('facebook-pixel', 'fbq', {});
  }

  _pageView() {
    this.lib('track', 'PageView');

    return 'PageView';
  }

  _track(event, params) {
    const trackType = STANDARD_EVENTS.includes(event) ? 'track' : 'trackCustom';

    this.lib(trackType, event, params);

    return {
      event,
      params,
    };
  }

  _init() {
    const lib = window[this.libName];

    if (lib) {
      return Promise.resolve(lib);
    }

    return Promise.reject();
  }
}

export default FacebookPixel;
