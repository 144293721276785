import rexCookies from '@rex-change/rexlib/dist/rexCookies';
import { REX_NO_TRACK_COOKIE, BUYER_LEAD_POPUP_COOKIE } from 'constants/cookies';
import getTestFromQueryParam from 'utils/queryParams';

const toggleTestCookie = (location) => {
  const { search } = location;
  const test = getTestFromQueryParam(search);

  if (test) {
    rexCookies.set(REX_NO_TRACK_COOKIE, true, { expireDays: 0 });
    rexCookies.set(BUYER_LEAD_POPUP_COOKIE, true, { expireDays: 0 });
  } else if (test === false && rexCookies) {
    if (rexCookies.isSet(REX_NO_TRACK_COOKIE)) {
      rexCookies.remove(REX_NO_TRACK_COOKIE);
    }
    if (rexCookies.isSet(BUYER_LEAD_POPUP_COOKIE)) {
      rexCookies.remove(BUYER_LEAD_POPUP_COOKIE);
    }
  }
};

export default toggleTestCookie;
