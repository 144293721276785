import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { redirect, parseRedirect } from 'helpers/redirect';
import Modal from 'components/Modal';
import './RegisterModal.scss';
import RegisterForm from '../RegisterForm';

function RegisterModal({ hash, titleCopy, descriptionCopy, ctaCopy }) {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const closeDialog = () => {
    setIsOpen(false);

    history.replace({
      ...location,
      hash: '',
    });
  };

  const onSuccess = () => {
    const redirectUrl = parseRedirect(location);

    closeDialog();

    if (redirectUrl && redirectUrl.startsWith('http')) {
      window.location = redirectUrl;
    } else if (redirectUrl) {
      redirect(redirectUrl, history);
    }
  };

  useEffect(() => {
    let mounted = true;

    const isMatch = location?.hash === hash;

    if (mounted) {
      setIsOpen(isMatch);
    }

    return () => {
      mounted = false;
    };
  }, [location.hash, hash, setIsOpen]);

  return (
    <Modal
      contentLabel={titleCopy}
      open={isOpen}
      onClose={closeDialog}
      className="rex-register-modal"
    >
      <RegisterForm descriptionCopy={descriptionCopy} ctaCopy={ctaCopy} onSuccess={onSuccess} />
    </Modal>
  );
}

RegisterModal.propTypes = {
  hash: PropTypes.string,
  titleCopy: PropTypes.string,
  descriptionCopy: PropTypes.string,
  ctaCopy: PropTypes.string,
};

RegisterModal.defaultProps = {
  hash: '#register',
  titleCopy: 'Create A New Account',
  descriptionCopy: undefined,
  ctaCopy: undefined,
};

export default RegisterModal;
