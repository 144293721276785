import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import Modal from 'components/Modal';
import logger from 'utils/logger';
import { PASSWORD_REGEX } from 'constants/passwordPolicy';
import { Formik, Form } from 'formik';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { makeStyles, Button, Box, Typography } from '@material-ui/core';
import { string as yupString, ref as yupRef, object as yupObject } from 'yup';
import FormikTextField from 'components/HumanlessListingFlow/Inputs/FormikTextField';

import { AuthClient } from '@rex-change/rexlib/dist/AuthClient';
import { REX_SVC_REX_APP_HOST } from 'constants/api';

const authClient = new AuthClient({
  baseURL: REX_SVC_REX_APP_HOST,
});

const id = uuid();

const useStyles = makeStyles((theme) => ({
  resetPasswordModal: {
    textAlign: 'center',
  },
  buttom: {
    textTransform: 'uppercase',
  },
  errorText: {
    padding: theme.spacing(4),
  },
}));

export const doTokenValidation = async (setTokenValid, location) => {
  const queryParams = new URLSearchParams(location?.search);
  const token = queryParams.get('token');
  try {
    if (token) {
      await authClient.validateToken(token);
      setTokenValid(true);
    } else {
      setTokenValid(false);
    }
  } catch {
    setTokenValid(false);
  }
};

export function ResetPasswordModal({ hash }) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currHash = location && location.hash;

  const [isOpen, setOpen] = useState(currHash === hash);
  const [isTokenValid, setTokenValid] = useState(true);

  const closeDialog = useCallback(() => {
    const loc = { ...location, hash: '', search: '' };
    history.replace(loc);
  }, [history, location]);

  const onSubmit = async ({ newPassword }, { setSubmitting }) => {
    const queryParams = new URLSearchParams(location?.search);
    const token = queryParams.get('token');
    try {
      await authClient.resetPassword(token, newPassword);
    } catch (err) {
      logger.error(err);
    }
    setSubmitting(false);
    closeDialog();
  };

  useEffect(() => {
    setOpen(currHash === hash);
  }, [currHash, hash]);

  useEffect(() => {
    doTokenValidation(setTokenValid, location);
  }, [location]);

  return (
    <Modal
      contentLabel="Change Your Password"
      open={isOpen}
      onClose={closeDialog}
      className={classes.resetPasswordModal}
    >
      {isTokenValid && (
        <Formik
          validationSchema={ResetPasswordModal.formSchema}
          initialValues={ResetPasswordModal.initialValues}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <Box>
                <FormikTextField
                  id={`${id}-new-password`}
                  name="newPassword"
                  label="New Password"
                  type="password"
                  fullWidth
                  className={classes.textField}
                />
                <FormikTextField
                  id={`${id}-confirmed-new-password`}
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  className={classes.textField}
                />
              </Box>
              {errors.form && (
                <Box m={4}>
                  <Typography variant="h5">
                    There was an issue saving your changes. Please check your passwords and try
                    again.
                  </Typography>
                </Box>
              )}
              <Box m={4}>
                <Button
                  className={classes.buttom}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  data-test-id="changePassword"
                >
                  Change your password
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      )}
      {!isTokenValid && (
        <div>
          <Typography variant="h6" className={classes.errorText}>
            Looks like your password link has expired.
          </Typography>
          <Link to="#forgot-password">Request a new password</Link>
        </div>
      )}
    </Modal>
  );
}

ResetPasswordModal.initialValues = {
  newPassword: '',
  confirmPassword: '',
};

ResetPasswordModal.formSchema = yupObject({
  newPassword: yupString()
    .required('You must enter a valid password.')
    .matches(PASSWORD_REGEX, 'Your password must be 6 - 30 Upper and Lower Case Letters & Numbers'),
  confirmPassword: yupString()
    .matches(PASSWORD_REGEX, 'Your password must be 6 - 30 Upper and Lower Case Letters & Numbers')
    .oneOf([yupRef('newPassword'), null], 'Passwords do not match'),
});

ResetPasswordModal.propTypes = {
  hash: PropTypes.string.isRequired,
};

export default ResetPasswordModal;
