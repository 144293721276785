import ENV from 'constants/env';

const level = ENV.LOG_LEVEL || 'info';

let logger;

if (__CLIENT__) {
  // eslint-disable-next-line global-require
  logger = require('loglevel');
  logger.setLevel(level);
} else {
  // eslint-disable-next-line global-require
  const { createLogger, format, transports } = require('winston');
  const { combine, timestamp, printf, colorize } = format;

  const myFormat = printf((info) => `[${info.timestamp}] ${info.level}: ${info.message}`);

  logger = createLogger({
    level,
    exitOnError: false,
    transports: [
      new transports.Console({
        handleExceptions: true,
        json: false,
        timestamp: true,
        format: combine(timestamp(), colorize(), myFormat),
        level,
      }),
    ],
  });

  logger.stream = {
    write: (message) => logger.info(message),
  };
}

logger.info(`Log Level: ${level}`);

export default Object.freeze(logger);
