import React from 'react';
import Redirect from 'components/Redirect';
import ExternalRedirect from 'components/ExternalRedirect';
import ENV from './env';

const { REX_USER_DASHBOARD_URL } = ENV;

const redirectMappings = [
  // This redirect is here as a workaround for the CRP dashboard
  // redirect without making changes to rex-app
  {
    path: '/user/dashboard',
    isExternal: true,
    redirectPath: `${REX_USER_DASHBOARD_URL}/plan`,
    status: 301,
  },
  {
    path: '/buy',
    isExternal: false,
    redirectPath: '/buy-with-rex',
    status: 301,
  },
  {
    path: '/buyer',
    isExternal: false,
    redirectPath: '/buy-with-rex',
    status: 301,
  },
  {
    path: '/compare/faira',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/homebay',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/jovio',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/justinturner',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/miami',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/purplebricks',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/radio',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/redefy',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/rex-zero',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/rex/new',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/compare/trelora',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/home',
    isExternal: false,
    redirectPath: '/',
  },
  {
    path: '/safe',
    isExternal: false,
    redirectPath: '/buy-with-rex',
    status: 301,
  },
  {
    path: '/secure',
    isExternal: false,
    redirectPath: '/buy-with-rex',
    status: 301,
  },
  {
    path: '/sell',
    isExternal: false,
    redirectPath: '/sell-with-rex',
    status: 301,
  },
  {
    path: '/email-landing',
    isExternal: false,
    redirectPath: '/',
  },
  {
    path: '/sandiego',
    isExternal: false,
    redirectPath: '/region/sandiego',
  },
  {
    path: '/sd',
    isExternal: false,
    redirectPath: '/region/sandiego',
  },
  {
    path: '/austin',
    isExternal: false,
    redirectPath: '/region/austin',
  },
  {
    path: '/newyork',
    isExternal: false,
    redirectPath: '/region/newyork',
  },
  {
    path: '/perkspot',
    isExternal: false,
    redirectPath: '/service/buyerrebate',
  },
  {
    path: 'buyer/perkspot',
    isExternal: false,
    redirectPath: '/service/buyerrebate',
  },
  {
    path: '/sellwithrex',
    isExternal: false,
    redirectPath: '/compare/rex',
  },
  {
    path: '/profile',
    isExternal: true,
    redirectPath: `${REX_USER_DASHBOARD_URL}/profile`,
  },
  {
    path: '/control-panel/:target?',
    isExternal: true,
    redirectPath: REX_USER_DASHBOARD_URL,
  },
  {
    path: '/rexhomeloans/:referrer',
    isExternal: false,
    redirectPath: '/home-loans',
  },
  {
    path: '/service/rexhomeloans/:referrer',
    isExternal: false,
    redirectPath: '/home-loans',
  },
  {
    path: '/service/rexhomeloans',
    isExternal: false,
    redirectPath: '/home-loans',
  },
  {
    path: '/rexhomeloans',
    isExternal: false,
    redirectPath: '/home-loans',
  },
  {
    path: '/service/allhomescashback',
    redirectPath: '/service/buyerrebate',
    status: 302,
  },
  {
    path: '/listing/:rexUrl/statistic',
    isExternal: true,
    redirectPath: `${REX_USER_DASHBOARD_URL}/profile`,
  },
  {
    path: '/listing/:rexUrl/statistic/:param?',
    isExternal: true,
    redirectPath: `${REX_USER_DASHBOARD_URL}/profile`,
  },
  {
    path: '/experimental/listing/:rexUrl/statistic',
    isExternal: true,
    redirectPath: `${REX_USER_DASHBOARD_URL}/profile`,
  },
  {
    path: '/listing/7446-s-elati-st',
    isExternal: false,
    redirectPath: '/listing/7446-s-elati-st-littleton',
  },
  {
    path: '/region/bend',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/boston',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/charlotte',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/fort-meyers',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/minneapolis',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/provo',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/research-triangle',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/salt-lake-city',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/stockton',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/san-antonio',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/region/tampa',
    isExternal: false,
    redirectPath: '/compare',
    status: 301,
  },
  {
    path: '/marketing-rex',
    isExternal: false,
    redirectPath: '/sell-with-rex',
    status: 301,
  },
  {
    path: '/find/*',
    isExternal: false,
    redirectPath: '/homes',
    status: 301,
  },
  {
    path: '/terms-and-privacy/consumer-concerns',
    isExternal: false,
    redirectPath: '/terms-and-privacy/licensing-and-disclosures',
    status: 301,
  },
];

export default redirectMappings.map((redirect) => ({
  path: redirect.path,
  exact: true,
  component: redirect.isExternal
    ? () => <ExternalRedirect location={{ pathname: redirect.redirectPath }} />
    : () => <Redirect from={redirect.path} to={redirect.redirectPath} status={redirect.status} />,
  fetchData: () => {},
}));
